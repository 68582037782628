import Page from '../components/Page';
import { Container, Button, Stack, Typography, CardActionArea, TextField, FormControl, FormGroup , InputLabel, Select, MenuItem, Input, Checkbox, Autocomplete, Popover} from '@mui/material';
import { useHistory } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SendIcon from '@mui/icons-material/Send';
import configData from "../config.json";
import { fetchUtils } from 'react-admin';
import jsonServerProvider from '../data_provider';
import { useEffect } from 'react';
import { useState, useCallback } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { forwardRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InfoIcon from '@mui/icons-material/InfoRounded';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ObjectTable from './objecttable';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function WEditor(params) {
	
  const html2xhtml = (html) => {
	try{
		if(!window.__html2xhtmlDOMParser){
			window.__html2xhtmlDOMParser = new DOMParser();
			window.__html2xhtmlXMLSerializer = new XMLSerializer();
		}
	
		var doc = window.__html2xhtmlDOMParser.parseFromString(html, 'text/html');
		return window.__html2xhtmlXMLSerializer.serializeToString(doc.body).replace(/<\/?body[^>]*>/gi,'');
	}catch(ex){
		return html;
	}	
  };	
  
  if(!window.__editors_content){
	  window.__editors_content = {};
  }
  
  if(!window.__editors_content_html){
	  window.__editors_content_html = {};
  }
  
  if(!window.__editors_content[params.editorIndentifier]){
	  window.__editors_content[params.editorIndentifier] = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML( params.HTMLValue || "")));
	  window.__editors_content_html[params.editorIndentifier] = params.HTMLValue;
  }
  
  const [editorState, setEditorState] = useState(window.__editors_content);
  const emptyIfNull = (val) => {
		if(val === null || val === undefined)
			return "";
		return val;
  };
  
  const _onEditorStateChange = function(state){
	  window.__editors_content[params.editorIndentifier] = state;
	  setEditorState(window.__editors_content);
  };
  
  const parametarName = params.parametarName;

  return <Editor 
				editorState={window.__editors_content[params.editorIndentifier]} 
				onEditorStateChange={_onEditorStateChange}
				wrapperClassName="wrapper-class"
				editorClassName="editor-class"
				toolbarClassName="toolbar-class"
				key={"w_" + params.editorIndentifier}
				required={params.required}
				placeholder={params.placeholder}
				defaultEditorState={EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('')))}
				onChange={(...args) => { 
					let html = draftToHtml(convertToRaw(editorState[params.editorIndentifier].getCurrentContent()));
					html = html2xhtml(html);
					
					if(window.__editors_content_html[params.editorIndentifier] != html){
						window.__editors_content_html[params.editorIndentifier] = html;
						args[0].html_changed = true;
					}
					
					args[0].html = html;
					params.onChange(...args);
				}}
				toolbar={{
					inline: { inDropdown: true },
					list: { inDropdown: true },
					textAlign: { inDropdown: true },
					link: { inDropdown: true },
					history: { inDropdown: true },
				}}
				/>;
}
	
export default function PaymentDetails() {
	//let currentPaymentMethodData = null;
	const moduleScope = "PaymentDetails";
    const history = useHistory();
    
	const [currentData, setCurrentData] = useState({});
	const [currentLocalization, setCurrentLocalization] = useState("default");
	
    const [paymentMethodName, setPaymentMethodName] = useState("");
	const [Enabled, setEnabled] = useState(false);
	const [Order, setOrder] = useState(0);
	
	const [Uid, setUid] = useState(null);
	const [ProcessGroup, setProcessGroup] = useState(null);
	
	const [fallbackOf, setFallbackOf] = useState(null);
	const [methodParameters, setMethodParameters] = useState([]);
	const [localizations, setLocalizations] = useState([]);
 	const [localizedValueObjects, setLocalizedValueObjects] = useState({default:{}});
    const [valueObject, setValueObject] = useState({});
	
	
	if(!window._methodParameters)
		window._methodParameters = {};
	
	if(!window._localizedValueObjects)
		window._localizedValueObjects = {default:{}};
	
	if(!window._valueObject)
		window._valueObject = {};
	
	if(!window._localizations)
		window._localizations = [];
	
	const _setMethodParameters = (params) => {
		if(params && params.length){
			window._methodParameters = params;
		}
		setMethodParameters(params);
	};
	
	const _setLocalizedValueObjects = (value) => {
		window._localizedValueObjects = value;
		setLocalizedValueObjects(value);
	};
	
	const _setValueObject = (value) => {
		window._valueObject = value;
		setValueObject(value);
	};
	
	const _setLocalizations = (value) => {
		window._localizations = value;
		setLocalizations(value);
	};
	
	const [supportsFallback, setSupportsFallback] = useState(false);
	
	const [sitePaymentMethods, setSitePaymentMethods] = useState([]);
	const [sitePaymentMethodsMap, setSitePaymentMethodsMap] = useState({});
	
	const [iSsaving, setIsSaving]  = useState(false);
	
	// eslint-disable-next-line
    const [AlertMessage, setAlertMessage] = useState("success");
    // eslint-disable-next-line
    const [AlertType, setAlertType] = useState("success");
    const [AlertOpen, setAlertOpen] = useState(false)
    const params = useParams();
    const { t } = useTranslation();
	
	const [__refreshView, __setRefreshView] = useState(0);
	
	const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
	
	const refreshView = () => {
		__setRefreshView(Math.random());
	};
	
	const AlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
    };

    const httpClient = (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json', xcompanyid: sessionStorage.company_id, xsiteid: sessionStorage.site_id  });
        }
        const token = JSON.parse(sessionStorage.AccessToken || "{}").Token;
        options.headers.set('Authorization', `${token}`);
        return fetchUtils.fetchJson(url, options);
    };

    const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);
    const handleSubmit = (e) => {
        if(e)
			e.preventDefault();
		
		if(window.__upload_files && Object.keys(window.__upload_files).length){
			let file_key = Object.keys(window.__upload_files)[0];
			
			var data = new FormData()
			data.append('file', window.__upload_files[file_key]);
			data.append('company_id', sessionStorage.company_id);
			
			fetch(configData.API_URL + "upload", {
			  method: 'POST',
			  headers:{
				  Authorization: JSON.parse(sessionStorage.AccessToken || "{}").Token
			  },
			  body: data
			}).then(rawresp => {
				return rawresp.json();
			}).then(resp => {
				
				if(resp.error){
					setAlertMessage(t("Upload blocked!") + " " + window.__upload_files[file_key].name);
					setAlertType("error");
					setAlertOpen(true);
				}
				
				delete window.__upload_files[file_key];
				setParameterValue(file_key, resp.error ? "" : resp.filename);
				setTimeout(handleSubmit, 200);
				
			}).catch(err => {
				setAlertMessage(t("Upload blocked!") + " " + window.__upload_files[file_key].name);
				setAlertType("error");
				setAlertOpen(true);
					
				delete window.__upload_files[file_key];
				setParameterValue(file_key, "");
				setTimeout(handleSubmit, 200);
			});
			return;
		}
		
		
        let savedata = {};
		
		methodParameters.forEach( param => {
			savedata[param.Name] = param.Default;
		});
		
        for (let prop in window._localizedValueObjects.default ) {
			if(window._localizedValueObjects.default.hasOwnProperty(prop)){
				savedata[prop] = window._localizedValueObjects.default[prop]; 
			}
        }
		
        const main_data = { "parameters": savedata, "localized_parameters": {} };
		
		for(let lng in window._localizedValueObjects){
			if(window._localizedValueObjects.hasOwnProperty(lng)){
				if(lng == 'default')
					continue;
				main_data.localized_parameters[lng] = {};
				methodParameters.forEach( param => {
					if(param.Localizable){
						main_data.localized_parameters[lng][param.Name] = window._localizedValueObjects[lng][param.Name];
					}else{
						main_data.localized_parameters[lng][param.Name] = savedata[param.Name];
					}
				});
				window._localizedValueObjects[lng] = main_data.localized_parameters[lng];
			}
		}
		
		setIsSaving(true);
	
        dataProvider.update('company_site_payment_methods', { 
				id: currentData.id, 
				data: { 
						PaymentMethodId: currentData.PaymentMethodId, 
						CompanySiteId: sessionStorage.site_id, 
						Data: JSON.stringify( main_data ), 
						Enabled: Enabled,
						Order: Order,
						Uid: Uid,
						ProcessGroup: ProcessGroup,
						FallbackOfId: fallbackOf || null	
					} 
			})
            .then(res => {
				setIsSaving(false);
				setAlertMessage(t("Site payment method data saved!"));
			    setAlertType("success");
			    setAlertOpen(true);
		    })
            .catch(error => {
				setIsSaving(false);
				if(error && error.body && error.body.error && /^401 /.test(error.body.error)){
					let event = new Event("hpay-auth-expired");
					window.dispatchEvent(event);
				}
			
			    setAlertMessage(t("Error saving site payment method data"));
			    setAlertType("error");
			    setAlertOpen(true);
                console.log(error)
            })
    }

    const back = (params) => {
        history.push('/paymentMethods')
    };

    const addLocalization = (lcode) => {
		lcode = lcode.trim().toLowerCase();
		
		if(!/(^[a-z]{2}$)|(^[a-z]{2}-[a-z]{3}$)/.test(lcode)){
			setAlertMessage(t("Bad language code. Please use 2-letters code, optionally with extension -cyr!"));
			setAlertType("error");
			setAlertOpen(true);
			return;
		}
		
		_setLocalizations(window._localizations.concat([lcode]));
		swapToLocalization(lcode);
    };
	
	const removeLocalization = (lcode) => {
		confirmAlert({
		  title: t('Remove localization') + " " + lcode + "?",
		  message: t('Please confirm you want remove localization') + ": " + lcode ,
		  buttons: [
			{
			  label: t('Remove'),
			  onClick: () => {
				_setLocalizations(window._localizations.filter(l => l != lcode));
				delete window._localizedValueObjects[lcode];
				_setLocalizedValueObjects(window._localizedValueObjects);
				swapToLocalization('default');
			  }
			},
			{
			  label: t('Cancel'),
			  onClick: () => {}
			}
		  ]
		});
    };
	
	const swapToLocalization = (lcode) => {
		
		if(!window._localizedValueObjects[lcode]){
			window._localizedValueObjects[lcode] = {};
		}
		
		if(lcode == "default"){
			setValueObject(window._localizedValueObjects.default);
		}else{
			let VOBJ = {};
			methodParameters.forEach( param => {
				if(param.Localizable){
					if(!window._localizedValueObjects[lcode].hasOwnProperty(param.Name)){
						window._localizedValueObjects[lcode][param.Name] = window._localizedValueObjects.default[param.Name];
					}
					VOBJ[param.Name] = window._localizedValueObjects[lcode][param.Name];
				}else{
					VOBJ[param.Name] = window._localizedValueObjects.default[param.Name];
				}
			});
			setValueObject(VOBJ);
		}
		setCurrentLocalization(lcode);
	};
	
	const setParameterValue = (name, value) =>{
		let locale = 'default';
		
		let vo_clone = Object.assign({},window._localizedValueObjects);
		
		if(!window._methodParameters)
			return;
		
		let parm = window._methodParameters.find(p => p.Name == name);
		if(parm){
			if(currentLocalization != "default"){
				if(parm && parm.Localizable){
					if(!vo_clone[currentLocalization]){
						vo_clone[currentLocalization] = {};
					}
					locale = currentLocalization;
				}
			}
			
			if(parm.ValueFilter && typeof parm.ValueFilter === 'function'){
				try{
					let v = parm.ValueFilter(value,vo_clone[locale], vo_clone.default);
					if(v !== undefined){
						value = v;
					}
				}catch(ex){
					console.error(ex);
				}
			}
			
			vo_clone[locale][name] = value;
		}
		
		let vo = Object.assign({}, vo_clone.default);
		vo = Object.assign(vo, vo_clone[currentLocalization]);
		
		for(var prop in vo){
			if(vo.hasOwnProperty(prop)){
				window._localizedValueObjects[locale][prop] = vo[prop];	
			}
		}
		
		_setValueObject(vo);
	};
	
	const renderPropHTML = (prop, value) => {
		try{
			if(prop.render && typeof prop.render === 'function'){
				return prop.render(prop, value);
			}
			return null;
		}catch(ex){
			console.log(ex);
			return null;
		}
	};
	
	const validateResult = (name, value) => {
		try{
			
			let parm = methodParameters.find(p => p.Name == name);
			
			if(parm){
				let locale = 'default';
				if(currentLocalization != "default"){
					if(parm && parm.Localizable){
						if(!window._localizedValueObjects[currentLocalization]){
							window._localizedValueObjects[currentLocalization] = {};
						}
						locale = currentLocalization;
					}
				}
			
				if(parm.Validator && typeof parm.Validator === 'function'){
					let result = parm.Validator(value,window._localizedValueObjects[locale], window._localizedValueObjects.default);
					if(result){
						return <p className='validation-error'>{result}</p>;
					}
				}
			}
		}catch(ex){
			return <p className='validation-error'>{t("bad value!")}</p>;
		}
		return null;
	};
	
	const insertDescription = (parameter) => {
		if(!parameter.Description){
			return null;
		}
		
		let id = "_popover_" + parameter.Name.toLowerCase().replace(/ /g,""); 
		
		let iicon = <InfoIcon onClick={(e) => { setPopoverAnchorEl(e.target); window[id] = true; refreshView(); }} key={"icon_" + id} className={"info-icon"} style={{  fontSize: 18, cursor:"pointer" }}/>
		
		return [
		iicon,
		<Popover
			key={"desc_" + id}
			id={id}
			open={!!window[id]}
			anchorEl={popoverAnchorEl}
			
			onClose={(e) => {window[id] = false; refreshView();} }
			anchorOrigin={{
			  vertical: 'bottom',
			  horizontal: 'left',
			}}
		  >
			<Typography className={"desc-popover"} sx={{ p: 2 }}>{t(parameter.Description)}</Typography>
		  </Popover>
		]  
	};
	
	const emptyIfNull = (val) => {
		if(val === null || val === undefined)
			return "";
		return val;
	};
	
	const load = async () => {
		const Id = params.id;
        sessionStorage.paymentId = Id;
		
		if (sessionStorage.company_id) {
		  setIsSaving(true);	
		  //WE LOAD ALL METHODS BECUSE THERE MIGHT BE PROP THAT IS RELATED TO OTHER METHOD ~ FallbackOf
		  dataProvider.getList('company_site_payment_methods', { pagination: { page: 1, perPage: 99999 }, sort: { field: 'Order', order: 'ASC' }, filter: {} })
			.then(res => {
			  window.__upload_files = {};
			  
			  let data_map = {};
			  let current_index = null;
			  
			  for(let i = 0; i < res.data.length;i++){
				  try{
					res.data[i].Data = JSON.parse(res.data[i].Data);
				  }catch(ex){
					res.data[i].Data = { parameters: {} };
				  }
				  
				  if(!res.data[i].Data){
					  res.data[i].Data = { parameters: {} };
				  }
				  
				  if(!res.data[i].Data.parameters){
					  res.data[i].Data.parameters = {};
				  }
				  
				  if(!res.data[i].PaymentMethod)
					  res.data[i].PaymentMethod = {Data: {parameters:[]}};
				  
				  if(!res.data[i].PaymentMethod.Data){
					  res.data[i].PaymentMethod.Data = {parameters:[]};
				  }
					
				  if(!res.data[i].PaymentMethod.Data.parameters){
					  res.data[i].PaymentMethod.Data.parameters = [];
				  }
				  
				  data_map[res.data[i].id] = res.data[i];
				  
				  if(res.data[i].id == Id){
					  current_index = i;
					  
					  res.data[i].PaymentMethod.Data.parameters =  res.data[i].PaymentMethod.Data.parameters.map(param => {
							for(let prop in param){
								if(param.hasOwnProperty(prop)){
									if(param[prop]){
										if(typeof param[prop] === 'string' || param[prop] instanceof String){
											if(/^function\(/.test(String(param[prop]))){
												try{
													eval("param[prop] = " + param[prop]);
												}catch(ex){}
											}
										}
									}
								}
							}
							return param;
					  });
					  
				  }
			  }
			  
			  setIsSaving(false);
			  
			  setSitePaymentMethods(res.data);
			  setSitePaymentMethodsMap(data_map);
			  
			  //setSupportsFallback(res.data[current_index].PaymentMethod.Fallback ? true : false);
			  setPaymentMethodName(res.data[current_index].PaymentMethod.Name);
			  setEnabled(res.data[current_index].Enabled);
			  setOrder(res.data[current_index].Order);
			  setFallbackOf(res.data[current_index].FallbackOfId);
			  
			  setUid(res.data[current_index].Uid || (res.data[current_index].PaymentMethod.Name));
			  setProcessGroup(res.data[current_index].ProcessGroup);
			  
			  let LVO = {"default": res.data[current_index].Data.parameters };
			  let locs = [];
			  
			  
			  for(var i = 0; i < res.data[current_index].PaymentMethod.Data.parameters.length; i++){
				let param = res.data[current_index].PaymentMethod.Data.parameters[i];
				
				if(res.data[current_index].Data.parameters[param.Name] === undefined){
					res.data[current_index].Data.parameters[param.Name] = param.Default || null;
				}
			  }
			  
			  if(res.data[current_index].Data.localized_parameters){
				  for(let lng in res.data[current_index].Data.localized_parameters){
					  if(res.data[current_index].Data.localized_parameters.hasOwnProperty(lng)){
						  LVO[lng] = res.data[current_index].Data.localized_parameters[lng];
					  }
					  locs.push(lng);
				  }
			  }
			  
			  _setLocalizations(locs);
			  _setLocalizedValueObjects(LVO);
			  _setMethodParameters(res.data[current_index].PaymentMethod.Data.parameters);
			  _setValueObject(res.data[current_index].Data.parameters);
			  
			  setCurrentData(res.data[current_index]);
			  
			})
			.catch(error => {
				setIsSaving(false);
				if(error && error.body && error.body.error && /^401 /.test(error.body.error)){
					let event = new Event("hpay-auth-expired");
					window.dispatchEvent(event);
				}
				
			  setAlertMessage(t("Error loading site payment methods!"));
			  setAlertType("error");
			  setAlertOpen(true);
		  
			  console.log(error)
			})	
		} else {
		  setIsSaving(false);
		  setAlertMessage(t("Please select a POS!"));
		  setAlertType("error");
		  setAlertOpen(true);
		}
	};
	
	const onDataReady = () => {
		load();	
	};
	
	useEffect(() => {
		
		window.refreshCurrentView = () =>  {refreshView()};
		
		window._methodParameters = {};
		window._localizedValueObjects = {default:{}};
		window._valueObject = {};
		window._localizations = [];
		window.__editors_content = {};
		
		
		
		let removeReadyListener = false;
		
		if(window.HPAYCompanies && window.HPAYCompanies[sessionStorage.company_id] && window.HPAYCompanies[sessionStorage.company_id].HPAYSites && window.HPAYCompanies[sessionStorage.company_id].HPAYSites[sessionStorage.site_id]){
			load();
		}else{
			window.addEventListener("hpay-sites-loaded", onDataReady, false);
			setIsSaving(true);
			removeReadyListener = true;
			
		}	
		
        window.addEventListener("hpay-site-changed", back, false);
		
		return () => {
			window.removeEventListener("hpay-site-changed", back, false);
			if(removeReadyListener){
				window.removeEventListener("hpay-sites-loaded", onDataReady, false);
			}
			
			if(window._methodParameters && window._methodParameters.length){
				try{
					window._methodParameters.forEach(p => {
						delete p.__init_done;
					});
				}catch(ex){
					//
				}
			}
		};
		
        // eslint-disable-next-line
    }, [])
    return (
         <Page title={t("Payment Methods")} data-view-generation={__refreshView}>
            <Snackbar open={AlertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={AlertClose}>
                <Alert onClose={AlertClose} severity={AlertType} sx={{ width: '100%' }}>
                    {AlertMessage}
                </Alert>
            </Snackbar>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {t("Payment Method Configuration")}: {paymentMethodName}
                    </Typography>
                    
					
					<Button
							variant="dark"
							onClick={(e) => back()}
							startIcon={<ExitToAppIcon />}
							color="secondary"
						>
							{t("Back to site payment methods...")}
					</Button>
                   
                </Stack>
            </Container>
            <Grid container justifyContent="center">
                <Grid item xs={8}>
                    <Card>
                        <CardContent>
							
                            <form action="/paymentmethods" onSubmit={handleSubmit}>
								<hr/>
								<h5>{t("LOCALIZATION")}: </h5>
								<Stack direction={{ xs: 'column', sm: 'row' }} paddingBottom={0} justifyContent="space-between">
									<Stack direction={{ xs: 'row', sm: 'row' }} paddingBottom={0}>
										
										<Button
											color={ currentLocalization == "default" ? "info" : "grey"}
											size="small"
											type="reset"
											variant="contained"
											onClick={(e) => {
												swapToLocalization('default');
											}}
										>
											{t("[DEFAULT]")}
										</Button>
										
										{
											localizations.map((l, key) => {
												return (<Button
												            key={key}
															color={ currentLocalization == l ? "info" : "grey"}
															size="small"
															type="reset"
															variant="contained"
															onClick={(e) => {
																swapToLocalization(l);
															}}
														>{l}</Button>)
											})
										}
										
										
									</Stack>
									{sessionStorage.CurrentUserRole != "staff" ?
									<Stack direction={{ xs: 'row', sm: 'row' }} paddingBottom={1}>
											
										<Button
											className='cmd-add-localization'
											color="secondary"
											size="small"
											endIcon={<Icon icon={plusFill} />}
											type="reset"
											variant="contained"
											onClick={(e) => {
												document.querySelector('.add-loacalization-panel').style.display = 'inline-block';
												//document.querySelector('.cmd-add-localization').style.display = 'none';
												
												if(document.querySelector('.cmd-remove-localization'))
													document.querySelector('.cmd-remove-localization').style.display = 'none';
											}}
										>
											{t("Add")}
										</Button>
										
										{currentLocalization != 'default' ? 
											<Button
												className='cmd-remove-localization'
												color="warning"
												size="small"
												endIcon={<DeleteForeverIcon />}
												type="reset"
												variant="contained"
												onClick={(e) => {
													removeLocalization(currentLocalization);
												}}
											>[{currentLocalization}]
											</Button> : null}
											
										<div className='add-loacalization-panel' style={{ display:'none' }}>
											<label>{t("Language code")}:</label>
											<br/>
											<input list='language_code_list' style={{padding:"6px", maxWidth:"100px"}} placeholder={t("2 letters code") + ("(-cyr)")} className="add-localization-code" type='text' maxLength='6'  />
											<datalist id="language_code_list">
												<option value="rs"/>
												<option value="rs-cyr"/>
												<option value="hr"/>
												<option value="ba"/>
												<option value="en"/>
												<option value="us"/>
												<option value="gb"/>
												<option value="de"/>
												<option value="fr"/>
												<option value="hu"/>
												<option value="it"/>
												<option value="ro"/>
												<option value="nl"/>
												<option value="bg"/>
												<option value="sp"/>
											</datalist>
											<Button
												color="secondary"
												size="small"
												type="reset"
												style={{margin:"5px"}}
												variant="contained"
												onClick={(e) => {
													document.querySelector('.add-loacalization-panel').style.display = 'none';
													
													//document.querySelector('.cmd-add-localization').style.display = 'flex';
													if(document.querySelector('.cmd-remove-localization'))
														document.querySelector('.cmd-remove-localization').style.display = 'flex';
													
													addLocalization(document.querySelector('.add-localization-code').value);
													document.querySelector('.add-localization-code').value = "";
												}}
											>✓</Button>
											<Button
												color="warning"
												size="small"
												type="reset"
												style={{margin:"5px"}}
												variant="contained"
												onClick={(e) => {
													document.querySelector('.add-loacalization-panel').style.display = 'none';
													//document.querySelector('.cmd-add-localization').style.display = 'flex';
													if(document.querySelector('.cmd-remove-localization'))
														document.querySelector('.cmd-remove-localization').style.display = 'flex';
												}}
											>×</Button>
										</div>
									</Stack> : null}
									
								</Stack>
								<hr/>
								<Stack spacing={4}>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} paddingTop={1}>
										<FormControl component="fieldset" key={"Enabled"}>
                                            <FormControlLabel className="method-enabled" key={"Enabled"} value={1} onChange={(e) => setEnabled(e.target.checked ? true : false)} required={true} control={<Checkbox color="secondary" checked={Enabled ? true : false} />} label={t("Enabled")} />
                                        </FormControl>
									</Stack> 
									<Stack style={{background:"aliceblue", fontSize:"80%", fontStyle:"italic",display: (iSsaving ? "none" : "flex")}} direction={{ xs: 'column', sm: 'row' }} flexWrap="wrap" justifyContent="space-evenly" spacing={1} paddingTop={0}>
										<span>
											<label style={{"minWidth":'140px'}}>{t("List order")}: </label>
											<input type="number"
											  label={t("List order")}
											  style={{"border":'1px solid #dbdbdb', "padding":"6px"}}
											  className='form-control method-list-order-value'
											  value={Order || 0}
											  onInput={(e) => setOrder(e.target.value)}
											  />
										</span>  
										
										<span>
											<label style={{"minWidth":'140px'}}>{t("Method UID")}: </label>
											<input type="text"
											  label={t("Method UID")}
											  style={{"border":'1px solid #dbdbdb', "padding":"6px"}}
											  className='form-control method-uid-value'
											  value={Uid || ""}
											  onInput={(e) => setUid(e.target.value)}
											  /> 
											  
											  <span>    
												{insertDescription({Name: t("Method UID"), Description: t("Should be unique! If you need to re-create POS method (because any reason) it's important you keep this value same")})}
											  </span>
										</span>  
										
										<span>
											<label style={{"minWidth":'140px'}}>{t("Process Group")}: </label>  
											<input type="text"
											  label={t("Process Group")}
											  style={{"border":'1px solid #dbdbdb', "padding":"6px"}}
											  className='form-control method-process-group'
											  value={ProcessGroup || ""}
											  onInput={(e) => setProcessGroup(e.target.value)}
											  /> 
											  <span>    
												{insertDescription({Name: t("Process Group"), Description: t("If multiple methods belong to same process group after one in order generates result rest of them are skipped.") })}
											  </span>    
										</span>  
									</Stack> 
									
									{ supportsFallback ?
									<Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} paddingTop={0}>
										
											<InputLabel style={{"minWidth":'140px', 'color':'#212B36'}} >{t("Fallback of")}</InputLabel>
											<Select
												fullWidth
												label={t("Fallback of")}
												required={false}
												value={fallbackOf || "NULL"}
												onChange={(e) => setFallbackOf( e.target.value == "NULL" ? null : e.target.value)}
											>
											<MenuItem key="null-val" value="NULL">--{t("none")}--</MenuItem>
												{
													
													sitePaymentMethods.filter(m => { return m.id != currentData.id && m.FallbackOfId != currentData.id && m.PaymentMethod.Fallback  }).map((sub_item, sub_key) => {
														return (
															<MenuItem key={sub_key} value={sub_item.id}>{sub_item.Data.parameters.Name}</MenuItem>

														)
													})
												}

											</Select>
										
									</Stack> : null}
									
                                    {
                                        // eslint-disable-next-line
                                        methodParameters ? methodParameters.map((item, key) => {
											
											try{
												
												if(methodParameters.length){
													if(item.Datalist){
														if(!item.datalistUpdate){
															try{
																item.datalistId = "datalist_" + item.Name.toLowerCase().replace(/ /g,"");
																item.datalistElement = document.getElementById(item.datalistId);
																if(!item.datalistElement){
																	item.datalistElement = document.createElement("datalist");
																	item.datalistElement.setAttribute("id",item.datalistId);
																	document.body.appendChild(item.datalistElement);
																}
																
																if(!item.inputProps){
																	item.inputProps = {list: item.datalistId, autoComplete:"off"}
																}else{
																	item.inputProps.list = item.datalistId;
																	item.inputProps.autoComplete = "off";
																}
																item.datalistUpdate = (datalistItems) => {
																	try{
																		
																		if(!item.datalistElement.parentNode){
																			item.datalistElement = document.createElement("datalist");
																			item.datalistElement.setAttribute("id",item.datalistId);
																			document.body.appendChild(item.datalistElement);
																		}
																		
																		item.datalistElement.innerHTML = "";
																		if(datalistItems){
																			for(var v in datalistItems){
																				if(datalistItems.hasOwnProperty(v)){
																					let opt = document.createElement("option");
																					opt.setAttribute("value", v);
																					opt.innerHTML = datalistItems[v] || "";
																					item.datalistElement.appendChild(opt);
																				}
																			}	
																		}
																	}catch(ex){
																		console.error(ex);
																	}
																};
																item.__datalist_done = true;
																item.datalistUpdate(item.Datalist);
															}catch(ex){
																//console.error(ex);
															}
														}else{
															try{
																item.datalistUpdate(item.Datalist);
															}catch(ex){
																console.error(ex);
															}
														}
													}
													if(item.init && !item.__init_done){
														try{
															item.init();
															item.__init_done = true;
														}catch(ex){
															//console.error(ex);
														}
													}
												}
									  
												if (item.Type === "Text") {
													return (
													
														<FormControl style={ (item.Hide || item.InputType == "hidden") ? {display: "none"} : {} }  key={key}>
														{insertDescription(item)}	
															<TextField
																id={"param_" + item.Name.toLowerCase().replace(/ /g,"")}
																fullWidth
																label={t(item.Name)}
																type={item.InputType || "text"}
																placeholder={t(item.Hint)}
																//defaultValue={item.Default}
																required={item.Required}
																value={emptyIfNull(valueObject[item.Name])}
																onChange={(e) => setParameterValue(item.Name,e.target.value)}
																inputProps={item.inputProps || {}}
																multiline={item.Multiline ? true : false}
																minRows={item.MinRows ? item.MinRows : 1}
																maxRows={item.MaxRows ? item.MaxRows : 4}
															/>
														{item.Localizable ? <span className='localizable'>🌐: {currentLocalization}</span> : null}
														{validateResult(item.Name, emptyIfNull(valueObject[item.Name]))}	
														</FormControl>
														
														
													)
												}
												else if (item.Type === "WYSIWYG") {
													return (
														<FormControl style={ item.Hide ? {display: "none"} : {} }  key={key}>
															<FormLabel component="legend" color="secondary">{item.Name}</FormLabel>
															{insertDescription(item)}
															<WEditor
																required={item.Required}
																placeholder={t(item.Hint)}
																currentLocalization={currentLocalization}
																editorIndentifier={"editor_" + moduleScope + "_" + Uid + "_" + item.Name + "_" + currentLocalization} 
																HTMLValue={emptyIfNull(valueObject[item.Name])}
																onChange={(e) =>{ 
																	setParameterValue(item.Name,e.html || e.blocks[0].text)
																}}
															/>
															{item.Localizable ?  <span className='localizable'>🌐: {currentLocalization}</span> : null}
															{validateResult(item.Name, emptyIfNull(valueObject[item.Name]))}	
														</FormControl>
													)
												}
												else if (item.Type === "Upload") {
													return (
														<FormControl style={ item.Hide ? {display: "none"} : {} }  key={key}>
															{insertDescription(item)}
															<FormLabel component="legend" color="secondary">{t(item.Name)} {t(item.Hint)}</FormLabel>
															<label htmlFor={"param_" + item.Name.toLowerCase().replace(/ /g,"")}>
															{emptyIfNull(valueObject[item.Name]) ?
																<p>{emptyIfNull(valueObject[item.Name])}<Button onClick={(e) => {
																	e.preventDefault();
																	//e.stopImmediatePropagation();
																	
																	delete window.__upload_files[item.Name];
																	setParameterValue(item.Name,"");
																	
															}}>&times;</Button><br/></p>: null}
															
																<input id={"param_" + item.Name.toLowerCase().replace(/ /g,"")} accept={ item.Accepts ? item.Accepts : ".png,.jpg,.gif" } type="file" style={{ display: "none" }} onChange={(e) => {
																	
																	let files = [];
																	for(let ind in e.target.files){
																		if(e.target.files.hasOwnProperty(ind)){
																			files.push(e.target.files[ind]);
																			break;
																		}
																	}
																	if(files.length)
																		window.__upload_files[item.Name] = files[0];
																	
																	setParameterValue(item.Name,files.map(f => f.name).join(", "));
																
																}} />
																<Button variant="contained" color="secondary" required={item.Required} component="span" size="large" fullWidth>
																	{item.Name} {t("Upload")}
																</Button>
															</label>
															{item.Localizable ? <span className='localizable'>🌐: {currentLocalization}</span> : null}
															{validateResult(item.Name, emptyIfNull(valueObject[item.Name]))}
														</FormControl>
													)
												}
												else if (item.Type === "Checkbox") {
													return (
														<FormControl style={ item.Hide ? {display: "none"} : {} }  component="fieldset" key={key}>
															{insertDescription(item)}
															<FormControlLabel key={key} value={emptyIfNull(valueObject[item.Name])} onChange={(e) => setParameterValue(item.Name,e.target.checked)} required={item.Required} control={<Checkbox id={"param_" + item.Name.toLowerCase().replace(/ /g,"")} color="secondary" checked={valueObject[item.Name] ? true : false} />} label={t(item.Name) + " " + (item.Hint ? ( "(" + t(item.Hint) + ")") : "")} />
															{item.Localizable ? <span className='localizable'>🌐: {currentLocalization}</span> : null}
															{validateResult(item.Name, emptyIfNull(valueObject[item.Name]))}
														</FormControl>
													)
												}
												else if (item.Type === "RADIO") {
													return (
														<FormControl style={ item.Hide ? {display: "none"} : {} }  component="fieldset" key={key}>
															{insertDescription(item)}
															<FormLabel component="legend" color="secondary">{t(item.Name)  + " " + (item.Hint ? ( "(" + t(item.Hint) + ")") : "")}</FormLabel>
															<RadioGroup id={"param_" + item.Name.toLowerCase().replace(/ /g,"")} row aria-label="gender"  name="row-radio-buttons-group" value={emptyIfNull(valueObject[item.Name])} onChange={(e) => setParameterValue(item.Name,e.target.value)}>
																{
																	(typeof item.Options === 'function' ? item.Options() : item.Options ).map((sub_item, sub_key) => {
																		if(sub_item && typeof sub_item === 'object'){
																			if(item.valueProperty && item.labelProperty){
																				return <FormControlLabel value={sub_item[item.valueProperty]} required={item.Required} key={sub_key} control={<Radio color="secondary" />} label={sub_item[item.labelProperty]} /> 
																			}else{
																				return <FormControlLabel value={Object.keys(sub_item)[0]} required={item.Required} key={sub_key} control={<Radio color="secondary" />} label={Object.values(sub_item)[0]} /> 
																			}
																		}else{
																			return <FormControlLabel value={sub_item} required={item.Required} key={sub_key} control={<Radio color="secondary" />} label={sub_item} /> 
																		}
																	})
																}
															</RadioGroup>
															{item.Localizable ? <span className='localizable'>🌐: {currentLocalization}</span> : null}
															{validateResult(item.Name, emptyIfNull(valueObject[item.Name]))}
														</FormControl>
													)
												}
												else if (item.Type === "Select") {
													return (
														<FormControl style={ item.Hide ? {display: "none"} : {} }  key={key}>
															{insertDescription(item)}
															<InputLabel id={"param_label-" + item.Name.toLowerCase().replace(/ /g,"")}>{t(item.Name) + " " + (item.Hint ? ( "(" + t(item.Hint) + ")") : "")}</InputLabel>
															<Select
																labelId={"param_label-" + item.Name.toLowerCase().replace(/ /g,"")}
																id={"param_" + item.Name.toLowerCase().replace(/ /g,"")}
																label={item.Name}
																required={item.Required}
																value={emptyIfNull(valueObject[item.Name])}
																onChange={(e) => setParameterValue(item.Name,e.target.value)}
															>
																{
																	(typeof item.Options === 'function' ? item.Options() : item.Options ).map((sub_item, sub_key) => {
																		if(sub_item && typeof sub_item === 'object'){
																			if(item.valueProperty && item.labelProperty){
																				return <MenuItem key={sub_key} value={sub_item[item.valueProperty]}>{sub_item[item.labelProperty]}</MenuItem>	
																			}else{
																				return <MenuItem key={sub_key} value={Object.keys(sub_item)[0]}>{Object.values(sub_item)[0]}</MenuItem>	
																			}
																		}else{
																			return <MenuItem key={sub_key} value={sub_item}>{sub_item}</MenuItem>
																		}
																	})
																}

															</Select>
															{item.Localizable ? <span className='localizable'>🌐: {currentLocalization}</span> : null}
															{validateResult(item.Name, emptyIfNull(valueObject[item.Name]))}
														</FormControl>
													)
												}else if (item.Type === "Multiselect") {
													
													
													
													let options = (typeof item.Options === 'function' ? item.Options() : item.Options );
													let val = [];
													if(options && options.length && valueObject[item.Name] && valueObject[item.Name].length){
														if(item.valueProperty){
															val = options.filter(o => Object.values(valueObject[item.Name]).indexOf(o[item.valueProperty]) > -1);
														}else{
															if(typeof options[0] === 'object'){
																if(!Array.isArray(valueObject[item.Name])){
																	valueObject[item.Name] = [valueObject[item.Name]];
																}
																val = options.filter(o => Object.values(valueObject[item.Name]).indexOf(Object.keys(o)[0]) > -1);
															}else{
																val = valueObject[item.Name];
																if(!Array.isArray(val)){
																	val = [val];
																}
															}
														}	
													}
													
													return (
														<FormControl style={ item.Hide ? {display: "none"} : {} }  key={key}>
															{insertDescription(item)}
															<Autocomplete
															  id={"param_" + item.Name.toLowerCase().replace(/ /g,"")}
															  sx={{ m: 1, width: 500 }}
															  multiple
															  options={options}
															  getOptionLabel={(option) => option}
															  disableCloseOnSelect
															  required={item.Required}
															  value={ val }
															  onChange={(e, value) => {
																  let v = null;
																  if(!value){
																	  setParameterValue(item.Name,[]);
																  }else if(!value.length){
																	  setParameterValue(item.Name,[]);
																  }else if(item.valueProperty){
																	  setParameterValue(item.Name,value.map(x => emptyIfNull(x[item.valueProperty])));
																  }else{
																	  if(typeof value[0] === 'object'){
																		  setParameterValue(item.Name,value.map(x => Object.keys(x)[0]));
																	  }else{
																		  setParameterValue(item.Name,value);
																	  }
																  }
															  }}
															  getOptionLabel={(option) => { return item.labelProperty ?  option[item.labelProperty] : ( typeof option === 'object' ? Object.values(option)[0] : option ) }}
															  renderInput={(params) => {
																  if(!params.inputProps){
																	  params.inputProps = {};
																  }
																  
																  params.inputProps.autoComplete = "new-password";
																  
																  return (
															    	<TextField
																	  {...params}
																	  variant="outlined"
																	  label={t(item.Name)}
																	  placeholder={t(item.Hint) || "..."}
																	/>
																  )
															  }}
															/>
															{item.Localizable ? <span className='localizable'>🌐: {currentLocalization}</span> : null}
															{validateResult(item.Name, emptyIfNull(valueObject[item.Name]))}
														</FormControl>
													)
													
												}else if (item.Type === "Checklist") {
													let opt = typeof item.ListOptions === 'function' ? item.ListOptions() : item.ListOptions;
													let val = valueObject[item.Name] || {};
													
													return (
														<FormControl style={ item.Hide ? {display: "none"} : {} }  key={key}>
															{insertDescription(item)}
															<FormLabel component="legend">{t(item.Name)}</FormLabel>
															<div>
															{
																opt.map(( item_key, item_key_index) => {
																	return <FormControlLabel key={item.Name + item_key + item_key_index}
																					control={
																					  <Checkbox key={item.Name + item_key + item_key_index + "checkbox"} checked={val[item_key] ? true : false} onChange={(e) => {
																						  let v = Object.assign({},valueObject[item.Name] || {});
																						  v[item_key] = e.target.checked ? 1 : 0;
																						  setParameterValue(item.Name,v);
																					  }} name={item.Name + "_" + item_key } />
																					}
																					label={
																						<>
																						<span key={item.Name + item_key + item_key_index + "name"} style={{fontSize:"50%"}}>{t(item_key) + " "}</span> 
																						{item.ListImages ? (item.ListImages[item_key] ? <img key={item.Name + item_key + item_key_index + "image"} src={item.ListImages[item_key].img || item.ListImages[item_key]} style={{maxHeight:"22px"}} /> : null) : null}
																						</>
																						}
																				  />
																	
																})
															}
															</div>
															{t(item.Hint)}
															{item.Localizable ? <span className='localizable'>🌐: {currentLocalization}</span> : null}
															{validateResult(item.Name, emptyIfNull(valueObject[item.Name]))}
														</FormControl>
													)	
												}else if (item.Type === "Table") {
													return (
														<FormControl style={ item.Hide ? {display: "none"} : {} }  key={key}>
															<br/>
															{insertDescription(item)}
															<FormLabel component="legend" color="secondary">{t(item.Name)}:</FormLabel>
															
															<ObjectTable
															  PrimaryIdentifier = {item.PrimaryIdentifier}
															  AllowAddRemoveOffset = {item.AllowAddRemoveOffset}
															  Columns = {item.Columns}
															  Data = { valueObject[item.Name] || item.Default || ((Array.isArray(item.Default) || item.UseArray) ? [] : {})}
															  MaxRows = {item.MaxRows || 9999}
															  onDataChange = {(data) => {
																  let d = Object.assign((Array.isArray(item.Default) || item.UseArray) ? [] : {}, data);
																  setParameterValue(item.Name,d);
															  }}
															>  
															</ObjectTable>
															{t(item.Hint)}
															{item.Image ? <img src={item.Image} style={{maxHeight:"100px"}} /> : null}
															{validateResult(item.Name, emptyIfNull(valueObject[item.Name]))}
														</FormControl>
													)
												}else if (item.Type === "Fixed") {
													return (
														<FormControl style={ item.Hide ? {display: "none"} : {} }  key={key}>
															<br/>
															{insertDescription(item)}
															{t(item.Name)}: 
															<span id={"param_" + item.Name.toLowerCase().replace(/ /g,"")} >{emptyIfNull(valueObject[item.Name])}</span>
															{item.render ? renderPropHTML(item, valueObject[item.Name]) : null}
															<br/>
															{t(item.Hint)}
															{item.Image ? <img src={item.Image} style={{maxHeight:"100px"}} /> : null}
															{validateResult(item.Name, emptyIfNull(valueObject[item.Name]))}
														</FormControl>
													)
												}
											}catch(ex){
												console.error(ex);
												return null;
											}
                                        }) : ""
                                    }
									{sessionStorage.CurrentUserRole != "staff" ?
                                    <Stack direction={{ xs: 'column', sm: 'column' }} spacing={2} paddingTop={0}>
                                        
										
										
										<LoadingButton
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            color="secondary"
											loading={iSsaving}
                                            endIcon={<SendIcon />}
                                            variant="contained"
                                        >
                                            {t("Save")}
                                        </LoadingButton>
                                        
                                    </Stack>: null}

                                </Stack>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Page >
    );
}
