import Page from '../components/Page';
import { Container, Stack, Select, MenuItem, Typography, CardActionArea, TextField, Button, FormControl, FormLabel, Input, Table, TableRow, TableHead, TableBody, TableCell,TableContainer } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import configData from "../config.json";
import { fetchUtils } from 'react-admin';
import jsonServerProvider from '../data_provider';
import querystring from "query-string"
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ImageIcon from '@mui/icons-material/Image';
import { useTranslation } from 'react-i18next';
import { forwardRef, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Link as RouterLink } from 'react-router-dom';
import AddTaskIcon from '@mui/icons-material/AddTask';

import RowButtons from './rowbuttons';
import ObjectTable from './objecttable';


import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CompanyDetails() {
	


    const history = useHistory();
	const params = useParams();
    // eslint-disable-next-line
    const [details, setdetails] = useState([]);
    const [UpdateId, setUpdateId] = useState("");
    const [Name, setName] = useState("");
    const [files, setFiles] = useState("");
    const [Country, setCountry] = useState("");
    const [Address, setAddress] = useState("");
	
	const [Data, setData] = useState({});
	
    const [Reg, setReg] = useState("");
    const [Tax, setTax] = useState("");
    const { t } = useTranslation();
	const [limitedPermissions, setLimitedPermissions] = useState(true); 
	const [isCreator, setIsCreator] = useState(false); 
	const [isHPaySupport, setIsHPaySupport] = useState(false); 
	
    const [AlertMessage, setAlertMessage]  = useState("success");
    const [AlertType, setAlertType]        = useState("success");
    const [AlertOpen, setAlertOpen]        = useState(false);
	
	const [userRole, setUserRole] = useState('admin');
	const [userEmail, setUserEmail] = useState('');
	const [inputValid, setInputValid] = useState({valid:null, message: ""});
	const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
	
	const [companyUsers, setCompanyUsers]  = useState([]);
	
	const [iSsaving, setIsSaving]  = useState(false);

    const AlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
    };

    const httpClient = (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json', xcompanyid: sessionStorage.company_id, xsiteid: sessionStorage.site_id  });
        }
        const token = JSON.parse(sessionStorage.AccessToken || "{}").Token;
        options.headers.set('Authorization', `${token}`);
        return fetchUtils.fetchJson(url, options);
    };

    const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);
    const load = (callback) => {
        //const query = querystring.parse(history.location.search);
        //const Id = query.id;
		const Id = params.id;
        dataProvider.getOne('companies', { id: Id })
            .then(response => {
				
                setdetails(response.data);
                const item = response.data;
                setUpdateId(item.id);
                setName(item.Name);
                setCountry(item.Country);
                setAddress(item.Address);
                setReg(item.RegNo);
                setTax(item.TaxNo);
				
				let data = item.Data;
				if(!data){
					data = {};
				}else if(typeof data === 'string' || data instanceof String){
					try{
						data = JSON.parse(data);
					}catch(ex){
						data = {};
					}
				}
				
				setData(data);
				
				let role = "staff";
				if(item.UserCompanies && item.UserCompanies[0] && item.UserCompanies[0].Role){
					role = item.UserCompanies[0].Role;
				}
				
				setLimitedPermissions(role == "staff");
				setIsCreator(role == "creator");
				setIsHPaySupport(role == "hpay-support");
				
				
				dataProvider.getList("user_companies",{pagination: { page: 1, perPage: 9999 }, sort: { field: 'id', order: 'ASC' }, filter: { CompanyId: item.id}}).then(resp => {
					
					setCompanyUsers(resp.data);
					
					if(callback){
						callback();
					}
					
				});
				
				
				
				if(document.querySelector(".upload-preview"))
					document.querySelector(".upload-preview").style["backgroundImage"] = "url(" + (item.LogoFile ? (configData.API_URL + "public/" + item.LogoFile) : "static/default.png") + ")";
				
            })
            .catch(err => {
				
				if(err && err.body && err.body.error && /^401 /.test(err.body.error)){
					let event = new Event("hpay-auth-expired");
					window.dispatchEvent(event);
				}
				
				setAlertMessage(t("Error on data load!"));
				setAlertType("error");
				setAlertOpen(true);
		
                console.log(err)
            });
    }
	
	const setLogoPreview = (file) => {
		if(!file){
			if(document.querySelector(".upload-preview"))
				document.querySelector(".upload-preview").style["backgroundImage"] = "";
		}else{
			const reader = new FileReader();
			reader.addEventListener(
				"load",
				() => {
					
				 if(document.querySelector(".upload-preview"))
					document.querySelector(".upload-preview").style["backgroundImage"] = "url(" + reader.result + ")";
				},
				false
			);

			if (file) {
				reader.readAsDataURL(file);
			}
		}
	}

    const Update = async (params) => {
        if (files === "") {
			setIsSaving(true);
            dataProvider.update('companies', { id: UpdateId, data: { Name: Name, Address: Address, Data: JSON.stringify(Data || {}), Country: Country, RegNo: Reg, TaxNo: Tax } })
                .then(response => {
					
					setIsSaving(false);
					
					if(window.HPAYCompanies){
						if(window.HPAYCompanies[UpdateId]){
							window.HPAYCompanies[UpdateId] = Object.assign(window.HPAYCompanies[UpdateId], response.data);
							if(sessionStorage.company_id == UpdateId){
								sessionStorage.CurrentCompany  = JSON.stringify({ id: response.data.id, name: response.data.Name });
							}
						}
					}
					
                    setAlertMessage(t("Updated!"));
                    setAlertType("success");
                    setAlertOpen(true);
                })
                .catch(error => {
					
					setIsSaving(false);
					
					if(error && error.body && error.body.error && /^401 /.test(error.body.error)){
						let event = new Event("hpay-auth-expired");
						window.dispatchEvent(event);
					}
					
					setAlertMessage(t("Error on data update!"));
					setAlertType("error");
					setAlertOpen(true);
					
                    console.log(error)
                });
        } else {
            const data = new FormData();
            data.append('file', files);
			
			setIsSaving(true);
			
			fetch(configData.API_URL + "upload", {
			  method: 'POST',
			  headers:{
				  Authorization: JSON.parse(sessionStorage.AccessToken || "{}").Token
			  },
			  body: data
			}).then(rawresp => {
				return rawresp.json();
			}).then(resp => {
				
				if(resp.error){
					setAlertMessage(t("Upload blocked!") + " " + files.name);
					setAlertType("error");
					setAlertOpen(true);
				}
				
				dataProvider.update('companies', { id: UpdateId, data: { Name: Name, LogoFile: resp.filename, Address: Address, Country: Country, RegNo: Reg, TaxNo: Tax } })
                        .then(response => {
							
							setIsSaving(false);
						    setAlertMessage(t("Updated!"));
                            setAlertType("success");
                            setAlertOpen(true);
							
							
					    })
                        .catch(error => {
							setIsSaving(false);
							
							if(error && error.body && error.body.error && /^401 /.test(error.body.error)){
								let event = new Event("hpay-auth-expired");
								window.dispatchEvent(event);
							}
							
							setAlertMessage(t("Error on data update!"));
							setAlertType("error");
							setAlertOpen(true);
					
                            console.log(error)
                        });
				
			}).catch(err => {
				setIsSaving(false);
				setAlertMessage(t("Upload blocked!") + " " + files.name);
				setAlertType("error");
				setAlertOpen(true);
			});
			
			
			
        }
    }

    const ItemDelete = () => {
		
		confirmAlert({
		  title: t('Delete company'),
		  message: t('Please confirm you want to delete company') + " '" + Name + "'." + t('You might lose important data releted to this company.') ,
		  buttons: [
			{
			  label: t('Delete'),
			  onClick: () => {
				  
				  setIsSaving(true);
				  
				  dataProvider.delete('companies', {
						id: UpdateId
					}).then(response => {
						setIsSaving(false);
						let event = new Event("hpay-entities-invalidate");
						window.dispatchEvent(event);
									
						history.push('/company')
					})
					.catch(error => {
						setIsSaving(false);
						if(error && error.body && error.body.error && /^401 /.test(error.body.error)){
							let event = new Event("hpay-auth-expired");
							window.dispatchEvent(event);
						}
						
						setAlertMessage(t("Error on data delete!"));
						setAlertType("error");
						setAlertOpen(true);
							
						console.log(error)
					});
			  }
			},
			{
			  label: t('Cancel'),
			  onClick: () => {}
			}
		  ]
		});
    };

    const back = (params) => {
		if(sessionStorage.addsite){
			history.push('/sites');
		}else{
			history.push('/company');
		}
    };
	
	const uploadImagePreview = (img) => {
		if(img){
			if(img.constructor.name === "File"){
				
				let reader = new FileReader();

				reader.addEventListener("load", () => {
					document.getElementById('upload_image_preview').className += " has-image";
					document.getElementById('upload_image_preview').style['--previewimage'] = "url(" + reader.result + ")";
					
				}, false);

				reader.readAsDataURL(img);
				
			}else if(typeof img === "string"){
				document.getElementById('upload_image_preview').className += " has-image";
				document.getElementById('upload_image_preview').style['--previewimage'] = "url(" + img + ")";
			}
		}
	};
	
	const removeAccessForUser = (UserCompany) => {
		
		confirmAlert({
		  title: t('Remove user access'),
		  message: t('Please confirm you want to remove') + " " + UserCompany.User.Email + " " + t("access to") + " " + Name ,
		  buttons: [
			{
			  label: t('Remove Access'),
			  onClick: () => {
				  
				 setIsSaving(true); 
				  
				 dataProvider.delete('user_companies', {
						id: UserCompany.id
					}).then(response => {
						
						setIsSaving(false);
						
						load();
						
					})
					.catch(error => {
						setIsSaving(false);
						
						if(error && error.body && error.body.error && /^401 /.test(error.body.error)){
							let event = new Event("hpay-auth-expired");
							window.dispatchEvent(event);
						}
						
						setAlertMessage(t("Error during access removal") + "!");
						setAlertType("error");
						setAlertOpen(true);
					});
			  }
			},
			{
			  label: t('Cancel'),
			  onClick: () => {}
			}
		  ]
		});
		
		
	};
	
	const addAccessForUserWithRole = (Email, Role) => {
		setIsSaving(true);
		
		dataProvider.create("user_companies",{ data: {
			CompanyId: params.id,
			UserId: Email,
			Role: Role
		}}).then(resp => {
			setIsSaving(false);
			if(resp && resp.data && resp.data.id){
				load(function(){
					setAddUserDialogOpen(false);	
				});
			}
		}).catch(error => {
			setIsSaving(false);
			
			if(error && error.body && error.body.error && /^401 /.test(error.body.error)){
				let event = new Event("hpay-auth-expired");
				window.dispatchEvent(event);
			}
						
			
			try{
				if(error.body.error.errors[0].path == "UserId" && error.body.error.errors[0].value === null){
					setAlertMessage(t("User with given email was not found") + "!");
					setAlertType("error");
					setAlertOpen(true);
					return;	
				}
			}catch(ex){
				//
			}
			
			try{
				if(error.body.error.name == "ForbiddenOperation"){
					setAlertMessage(t("You can not manage yourself") + "!");
					setAlertType("error");
					setAlertOpen(true);
					return;	
				}
			}catch(ex){
				//
			}
			
			setAlertMessage(t("Error adding access for user") + "!");
			setAlertType("error");
			setAlertOpen(true);
			return;	
		});
	};
	
	
	const updateAccessRole = (UserCompany, Role) => {
		setIsSaving(true);
		
		confirmAlert({
		  title: t("Change POS user role"),
		  message: t("Please confirm you want change role of") + " " + UserCompany.User.Email + " " + t("to") + " '" + Role + "'. " + (Role == "creator" ? (t("Only one user can be POS 'creator' therefore current 'creator' will became 'admin'.")) : ""),
		  buttons: [
			{
			  label: t('Set new role'),
			  onClick: () => {
				  
				 setIsSaving(true); 
				 
				 dataProvider.update("user_companies",{id: UserCompany.id,  data: {
					Role: Role
					}}).then(resp => {
						setIsSaving(false);
						if(resp && resp.data && resp.data.id){
							load();
						}
					}).catch(error => {
						setIsSaving(false);
						if(error && error.body && error.body.error && /^401 /.test(error.body.error)){
							let event = new Event("hpay-auth-expired");
							window.dispatchEvent(event);
						}
									
						setAlertMessage(t("Error changing user role") + "!");
						setAlertType("error");
						setAlertOpen(true);
						return;	
					});
			  }
			},
			{
			  label: t('Cancel'),
			  onClick: () => {}
			}
		  ]
		});
	};

	const validateEmail = (email) => {
	  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(email).toLowerCase());
	};
	
	const handleAddUser = () => {
		setAddUserDialogOpen(true);
	};
	
	const handleUserRoleChange = (role) => {
		setUserRole(role.selectedValue || role);
	};
	
	const handleEmailInputChange = (email) => {
		setUserEmail(email);
		
		inputValid.valid = validateEmail(email);
		
		if(inputValid.valid === false){
			inputValid.message = t("Please enter valid email address");
		}else{
			inputValid.message = "";
		}
		
		setInputValid(inputValid);
	};
	
	const handleClose = () => {
		setAddUserDialogOpen(false);
	};
	
	const company_roles = [
	  { value: 'admin', label: t('Administrator') },
	  { value: 'staff', label: t('Staff') }
	];
	
	const me_id = JSON.parse( sessionStorage.AccessToken || "{}").UserId;
	
    useEffect(() => {
        load();
		
		
		
        // eslint-disable-next-line
    }, [])
    return (
        <Page title="Company | Holest">
            <Snackbar open={AlertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={AlertClose}>
        		<Alert onClose={AlertClose} severity={AlertType} sx={{ width: '100%' }}>
                    {AlertMessage}
                </Alert>
		    </Snackbar>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {t("Company Details")}
                    </Typography>
					
					{(!sessionStorage.windowedby || sessionStorage.addsite) ?
                    <Button
                        variant="dark"
                        onClick={(e) => back()}
                        startIcon={<ExitToAppIcon />}
                        color="secondary"
                    >
                        {t("Back to companies list...")}
                    </Button> : null}
                </Stack>
            </Container>
            <Grid container justifyContent="center">
                <Grid item xs={9}>
                    <Card>
                        <CardActionArea>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    {t("Details")}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardContent>
                            {/* <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}> */}
                            <Stack spacing={3}>
                                <TextField
                                    fullWidth
                                    label={t("Company Name")}
                                    value={Name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={5}>
                                    <TextField
                                        fullWidth
                                        label={t("Country")}
                                        value={Country}
										inputProps={{ list: "global_country_list" }}
                                        onChange={(e) => setCountry(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label={t("Address")}
                                        value={Address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={5}>
                                    <TextField
                                        fullWidth
                                        label={t("ResNo")}
                                        value={Reg}
                                        onChange={(e) => setReg(e.target.value)}
                                    />

                                    <TextField
                                        fullWidth
                                        label={t("TaxNo")}
                                        value={Tax}
										
                                        onChange={(e) => setTax(e.target.value)}
                                    />
                                </Stack>
								
								
								<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={5}>
									{!limitedPermissions ?
									<FormControl >
									<Typography variant="span" gutterBottom>
										{t("Company Warehouses/Delivery Partners")}
									</Typography>
									<Typography variant="p" style={{"fontSize":"60%"}}>
										*{t("important if you use shipping")}
									</Typography>
									
										<ObjectTable
										  PrimaryIdentifier = {"Uid"}
										  AllowAddRemoveOffset = {1}
										  MaxRows={3}
										  Columns = {{
											"Uid": { Property: "Uid", HeaderText: t("Identifier")}, 
											"Name": { Property: "Name", HeaderText: t("Name")},
											"Country": { Property: "Country", HeaderText: t("Country")},
											"City": { Property: "City", HeaderText: t("City")},
											"PostalCode": { Property: "PostalCode", HeaderText: t("Postal Code"), required: true},
											"StreetName": { Property: "StreetName", HeaderText: t("Street Name"), required: true},
											"StreetNumber": { Property: "StreetNumber", HeaderText: t("Street Number"), required: true},
											"CODBankAccount": { Property: "CODBankAccount", HeaderText: t("Bank Account"), required: false},
											"Email": { Property: "Email", HeaderText: t("E-Mail"), required: false},
											"Tel": { Property: "Tel", HeaderText: t("Tel"), required: false},
											"ContactName": { Property: "ContactName", HeaderText: t("Contact Name"), required: false}
										  }}
										  Data = { (Data || {}).Warehouses || {
												default: {  
													Uid: "default",
													Name: t("Default"),
													Country: Country,
													City:"-",
													PostalCode: "-",
													StreetName: "-",
													StreetNumber: "-",
													CODBankAccount: "-"
												}
											}}
										  onDataChange = {(data) => {
											  
											  let d = Object.assign({},Data);
											  d = Object.assign(d, {Warehouses: data})
											  setData(d);
											  
										  }}
										>  
										</ObjectTable>
										
									</FormControl> : null}
								</Stack>
								
								{!limitedPermissions ?
								<Stack >
								<Typography variant="span" gutterBottom>
										{t("Company users")}
									</Typography>
									<TableContainer sx={{  }}>
									  <Table>
									    <TableHead>
											<TableRow>
												<TableCell>
													{t("User")}
												</TableCell>
												<TableCell>
													{t("Name")}
												</TableCell>
												<TableCell>
													{t("Telephone")}
												</TableCell>
												<TableCell>
													{t("Role")}
												</TableCell>
												<TableCell>
													{t("Actions")}
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
										  {companyUsers.map((row, key) => {
											  //const { Id, siteurl, siteurls, companyname, date } = row;
											  //const isItemSelected = sessionStorage.site_id == Id;
											  
											  const mitems = [{text:"____________", icon:"none", callback: () => {}}];
												  
											  if(row.Role != "creator"){
												  mitems.push({text:t("Remove access"), icon:"delete", callback: () => {
													removeAccessForUser(row);
												  }});
											  }
											  
											  if(row.Role == "admin"){
												  mitems.push({text:t("Set role: staff"), icon:"edit", callback: () => {
													 updateAccessRole(row,"staff");
												  }});
												  
												  if(isCreator || isHPaySupport){
													  mitems.push({text:t("Set role: owner"), icon:"edit", callback: () => {
														updateAccessRole(row,"creator");
													  }});
												  }
											  }
											  
											  if(row.Role == "staff"){
												  mitems.push({text:t("Set role: admin"), icon:"edit", callback: () => {
													 updateAccessRole(row,"admin");
												  }});
												  
												  if(isCreator || isHPaySupport){
														 mitems.push({text:t("Set role: owner"), icon:"edit", callback: () => {
															updateAccessRole(row,"creator");
														  }}); 
												  }
											  }
												  
											  return (
												<TableRow
												  hover
												  key={key}
												  tabIndex={-1}
												  role="checkbox"
												  //selected={isItemSelected}
												  //aria-checked={isItemSelected}
												  className={'clickable' + (row.Role == "hpay-support" ? " hpay-support-user ": "")}
												>
												  <TableCell style={ (row.Role == "creator" ? {fontWeight:"bold"} : {}) } >
													{row.User.Email}
												  </TableCell>
												  <TableCell style={ (row.Role == "creator" ? {fontWeight:"bold"} : {}) } >
													{row.User.Name}
												  </TableCell>
												  <TableCell style={ (row.Role == "creator" ? {fontWeight:"bold"} : {}) } >
													{row.User.Tel}
												  </TableCell>
												  <TableCell align="left"  style={ (row.Role == "creator" ? {fontWeight:"bold"} : {}) } >
													{t(row.Role)}
												  </TableCell>
												  <TableCell align="right">
												  {
													row.Role == "hpay-support" ?  (<span className='instead-of-edit'>{t("HPay Support")}</span>) : (
														row.User.id == me_id ? (<span className='instead-of-edit'>{t("Yourself")}</span>) :(
															<RowButtons Items={mitems} />
														)
													)
												  }	
												  </TableCell>
												</TableRow>
											  );
											})}
										  
										</TableBody>
										<TableHead>
										 
										</TableHead> 
									  </Table>
									</TableContainer>
								</Stack> : null}
								{!limitedPermissions ?
								<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
								  <Button
									variant="contained"
									onClick={handleAddUser}
									component={RouterLink}
									to="#"
									startIcon={<AddTaskIcon />}
									color="secondary"
								  >
									{t("Add user")}
								  </Button>

								  <Dialog open={addUserDialogOpen} onClose={handleClose} fullWidth={true} maxWidth="sm">
									<DialogTitle>{t("Add access for a user")}</DialogTitle>
									<DialogContent>
									  <DialogContentText>
										{t("Add a user access for the company") + ": " + Name}
									  </DialogContentText>
									  <br/>
									  <DialogContentText>
										{t("Make sure that a user you are adding access for has an HolestPay account. If not please instruct him/her to create a account first.")}
									  </DialogContentText>
									  <br/>
									  
									  {t("Role")}: <Select
											labelId="post_type_label"
											id="post_type"
											value={userRole}
											label={t("Role")}
											onChange={(e) => handleUserRoleChange({selectedValue: e.target.value })}
										>
											{
												company_roles.map((sub_item, sub_key) => {
													return (
														<MenuItem key={sub_item.value} value={sub_item.value}>{sub_item.label}</MenuItem>
													)
												})
											}

										</Select>
									  
									  
									  <TextField
										autoFocus
										margin="dense"
										label={t("Email")}
										type="text"
										fullWidth
										variant="standard"
										error={inputValid.valid === false}
										helperText={inputValid.message}
										value={userEmail}
										onChange={(e) => handleEmailInputChange(e.target.value)}
									  />
									  
									  
									</DialogContent>
									<DialogActions>
									  <Button disabled={inputValid.valid !== true} onClick={(e) => addAccessForUserWithRole( userEmail, userRole )}>{t("Add user")}</Button>
									  <Button onClick={handleClose}>{t("Cancel")}</Button>
									</DialogActions>
									
									
									
								  </Dialog>
								</Stack> : null}
								
								<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={5}>
									{!limitedPermissions ?
									<FormControl >
										<div id="upload_image_preview" ></div>
										<label htmlFor="contained-button-file">
											<Input accept="image/*" id="contained-button-file" onChange={(e) => {setLogoPreview(e.target.files[0]); setFiles(e.target.files[0]); uploadImagePreview(e.target.files[0])} } type="file" style={{ display: "none" }} />
											<Button endIcon={<ImageIcon />} variant="contained" color="secondary" component="span" size="small" fullWidth>
												{t("Logo upload")}
											</Button>
										</label>
									</FormControl> : null}
									
									<div className='upload-preview'></div>
								</Stack>
								{!limitedPermissions ?
								<Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} paddingTop={1}>
                                    <LoadingButton
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        endIcon={<CheckIcon />}
                                        variant="contained"
										loading={iSsaving}
                                        onClick={(e) => Update()}
                                        color="secondary"
                                    >
                                        {t("Update")}
                                    </LoadingButton>
									{ (UpdateId && !sessionStorage.windowedby) ?
                                    <LoadingButton
                                        //fullWidth
                                        color="error"
                                        size="large"
										loading={iSsaving}
										endIcon={<DeleteForeverIcon />}
                                        type="reset"
                                        variant="contained"
                                        onClick={(e) => ItemDelete()}
                                    >
                                        {t("Delete")}
                                    </LoadingButton>
									: null
									}
                                </Stack> : null}

                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Page>
    );
}
