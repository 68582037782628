import { filter } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';
import { Card, Table, TableHead, TableRow, TableBody, TableCell, Container, Typography, TableContainer, TablePagination } from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../components/_dashboard/user';
import configData from "../config.json";
import { fetchUtils } from 'react-admin';
import jsonServerProvider from '../data_provider';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/*
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
*/

export default function SupportTicketsList() {
  //const [page, setPage] = useState(1);
  //const [rowsPerPage, setRowsPerPage] = useState(50);
  
  //const [order, setOrder] = useState('asc');
  //const [selected, setSelected] = useState([]);
  //const [orderBy, setOrderBy] = useState('CreatedAt');
  const [filterName, setFilterName] = useState('');
  
  const [Tickets, setTickets] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  
  const [AlertMessage, setAlertMessage] = useState("success");
  const [AlertType, setAlertType] = useState("success");
  const [AlertOpen, setAlertOpen] = useState(false);
  
  const [loading, setLoading] = useState(true);

  // const TABLE_HEAD = [
    // { id: 'Date', label: t('Date'), alignRight: false },
	// { id: 'Title', label: t('Issue'), alignRight: false },
    // { id: 'User', label: t('Raised by'), alignRight: false },
    
  // ];


  const AlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const httpClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json', xcompanyid: sessionStorage.company_id, xsiteid: sessionStorage.site_id  });
    }
    const token = JSON.parse(sessionStorage.AccessToken || "{}").Token;
    options.headers.set('Authorization', `${token}`);
    return fetchUtils.fetchJson(url, options);
  };
  const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);
  // const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === 'asc';
    // setOrder(isAsc ? 'desc' : 'asc');
    // setOrderBy(property);
  // };

  // const handleSelectAllClick = (event) => {
	  // return ;
    // if (event.target.checked) {
      // const newSelecteds = Tickets.map((n) => n.siteurl);
      // setSelected(newSelecteds);
      // return;
    // }
    // setSelected([]);
  // };

  // const handleChangePage = (event, newPage) => {
    // setPage(newPage);
	// loadData();
  // };

  // const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
	// loadData();
  // };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  //const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Tickets.length) : 0;

  //const filteredTickets = applySortFilter(Tickets, getComparator(order, orderBy), filterName);

  //const isUserNotFound = filteredTickets.length === 0;
  const details = (Id) => {
    const url = `/ticketdetails/${Id}`;
    history.push(url);
  }
  const loadData = (params) => {
    dataProvider.getList("tickets", { pagination: { page: 1, perPage: 99999 }, sort: { field: 'id', order: 'DESC' }, filter: {
		
		CompanyId: sessionStorage.company_id
		
	} })
      .then(res => {
        
		setLoading(false);
        setTickets(res.data);
      }).catch(err => {
		  
		    if(err && err.body && err.body.error && /^401 /.test(err.body.error)){
				let event = new Event("hpay-auth-expired");
				window.dispatchEvent(event);
			}
		  
			setAlertMessage(t("Error loaging support ticket list") + "!");	
			setAlertType("error");
			setAlertOpen(true);
		  
	  });
  }
  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [])

  return (
    <Page title="Subscriptions | Holest">
	  <Snackbar open={AlertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={AlertClose}>
        <Alert onClose={AlertClose} severity={AlertType} sx={{ width: '100%' }}>
          {AlertMessage}
        </Alert>
      </Snackbar>
      <Container>
        <Card>
          <UserListToolbar
            //numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
			  {/*
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={Tickets.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
			  */}
			  
				<TableHead>
					<TableRow>
						<TableCell>{t("Date/Time")}</TableCell>
						<TableCell>{t("Issue description")}</TableCell>
						<TableCell>{t("Raised by")}</TableCell>
						<TableCell>{t("Concerning company")}</TableCell>
						<TableCell>{t("Concerning POS")}</TableCell>
						<TableCell>{t("Status")}</TableCell>
					</TableRow>
				</TableHead>
				
                <TableBody>
                  {Tickets.filter(t => {
					  
					  if(!filterName.trim())
						  return true;
					  
					  let filterLC = filterName.toLowerCase();
					  
					  if(t.Title.toLowerCase().indexOf(filterLC) > -1){
						  return true;
					  }
					  
					  if(t.TicketEntries && t.TicketEntries.length){
						  if(t.TicketEntries.filter(ent => { return (ent.Content || "").toLowerCase().indexOf(filterLC) > -1 }).length > 0){
							  return true;
						  }
					  }
					  
					  return false;
					  
				  })
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, key) => {
                      
                      //const isItemSelected = selected.indexOf(row.id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={key}
                          tabIndex={-1}
                          role="checkbox"
                          //selected={isItemSelected}
                          //aria-checked={isItemSelected}

                        >
						  <TableCell align="left" onClick={(e) => details(row.id)}>{row.CreatedAt.split(".")[0].replace("T"," ")}</TableCell>
                          <TableCell onClick={(e) => details(row.id)} component="th" scope="row">
                            <Typography variant="subtitle2" noWrap>
                              {row.Title}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" onClick={(e) => details(row.id)}>{row.User.Email}</TableCell>
						  <TableCell align="left" onClick={(e) => details(row.id)}>{row.Company.Name}</TableCell>
						  <TableCell align="left" onClick={(e) => details(row.id)}>{row.CompanySite ? row.CompanySite.Url : ""}</TableCell>
						  <TableCell align="left" onClick={(e) => details(row.id)}>{row.Closed ? t("Closed") : <span style={{color:"red"}}>{t("Open")}</span>}</TableCell>
						  
                         
                        </TableRow>
                      );
                    })}
                  {/*emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )*/}
                </TableBody>
                
              </Table>
            </TableContainer>
          </Scrollbar>
		  {/*
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={Tickets.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
		  */}
        </Card>
      </Container>
	  {loading ? <div className={"center-child"}><div className={"lds-roller"}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>  : null}
    </Page>
  );
}
