import { forwardRef, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import AddTaskIcon from '@mui/icons-material/AddTask';
import Page from '../components/Page';
// import { ProductSort } from '../components/_dashboard/products';
import { Button, Container, Stack, Typography, CardActions } from '@mui/material';
import { useHistory } from 'react-router-dom';
import configData from "../config.json";
import { fetchUtils } from 'react-admin';
import jsonServerProvider from '../data_provider';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import Label from '../components/Label';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
//import SelectSite from './SelectSite'
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function EcommerceShop() {
  // eslint-disable-next-line
  const [openFilter, setOpenFilter] = useState(false);
  const [Companydata, setCompanydata] = useState([]);
  const History = useHistory();
  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [open_select, setOpenSelect] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("success");
  const [AlertType, setAlertType] = useState("success");
  const [AlertOpen, setAlertOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  
  const [loading, setLoading] = useState(true);
  
  
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const AlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const details = (item) => {
    History.push(`/companydetails/${item.Id}`);
  }

  const httpClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json', xcompanyid: sessionStorage.company_id || "", xsiteid: sessionStorage.site_id || ""  });
    }
    const token = JSON.parse(sessionStorage.AccessToken || "{}").Token;
    options.headers.set('Authorization', `${token}`);
    return fetchUtils.fetchJson(url, options);
  };
  const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);
  const MainUserId = JSON.parse(sessionStorage.AccessToken || "{}").UserId

  const formik = useFormik({
    initialValues: {
      category: '',
      colors: '',
      priceRange: '',
      rating: ''
    },
    onSubmit: () => {
      setOpenFilter(false);
    }
  });
  // eslint-disable-next-line
  const { resetForm, handleSubmit } = formik;
  const NewCompany = (params) => {
    History.push('/newcompany');
  }

  const ItemDelete = (ID, Name) => {
	  
	  confirmAlert({
		  title: t('Delete company'),
		  message: t('Please confirm you want to delete company') + " '" + Name + "'." + t('You might lose important data releted to this company.') ,
		  buttons: [
			{
			  label: t('Delete'),
			  onClick: () => {
				  dataProvider.delete('companies', {
								  id: ID
								}).then(response => {
									loadData("delete");
									
									
									let event = new Event("hpay-entities-invalidate");
									window.dispatchEvent(event);
									
								  })
								  .catch(err => {
									  
									if(err && err.body && err.body.error && /^401 /.test(err.body.error)){
										let event = new Event("hpay-auth-expired");
										window.dispatchEvent(event);
									}
				
									console.log(err)
								  });
			  }
			},
			{
			  label: t('Cancel'),
			  onClick: () => {}
			}
		  ]
		});
	  
    

  }

  const loadData = (param) => {
    const res_data = [];
    dataProvider.getList('companies', {
      pagination: { page: 1, perPage: 9999 },
      sort: { field: 'name', order: 'ASC' },
      filter: {  }
    })
      .then(response => {
        const data = response.data;
        for (let i = 0; i < data.length; i++) {
		  let role = "staff";
		  
		  if(data[i].UserCompanies && data[i].UserCompanies[0] && data[i].UserCompanies[0].Role){
			  role = data[i].UserCompanies[0].Role;
		  }
		  
	      res_data.push({
            Id: data[i].id,
            Name: data[i].Name,
            LogoFile : data[i].LogoFile,
            RegNo: data[i].RegNo,
            TaxNo: data[i].TaxNo,
            Address: data[i].Address,
			Country: data[i].Country,
			CurrentUserRole: role
          });
		  
        }
        if (res_data.length === 0) {
          sessionStorage.CurrentCompany = JSON.stringify({ id: "", name: t("--not selected--") });
		  sessionStorage.company_id = "";
		  sessionStorage.CurrentUserRole = "";
		  localStorage.lastCompany = sessionStorage.CurrentCompany;
		  localStorage["hpay_last_company_id"] = "";
		  
          setAlertMessage(t('Please create a company'));
          setAlertType("info");
          setAlertOpen(true);
        }
        else if (param === "delete") {
          sessionStorage.CurrentCompany = JSON.stringify({ id: res_data[0].Id, name: res_data[0].Name });
		  sessionStorage.company_id = res_data[0].Id;
		  sessionStorage.CurrentUserRole = res_data[0].CurrentUserRole;
		  localStorage.lastCompany = sessionStorage.CurrentCompany;
		  localStorage["hpay_last_company_id"] = sessionStorage.company_id;
		}
        else if (res_data.length === 1) {
          sessionStorage.CurrentCompany = JSON.stringify({ id: res_data[0].Id, name: res_data[0].Name });
		  sessionStorage.company_id = res_data[0].Id;
		  sessionStorage.CurrentUserRole = res_data[0].CurrentUserRole;
		  localStorage.lastCompany = sessionStorage.CurrentCompany;
		  localStorage["hpay_last_company_id"] = sessionStorage.company_id;
        }
		
		setLoading(false);
		
        setCompanydata(res_data);
		
      })
      .catch(err => {
		if(err && err.body && err.body.error && /^401 /.test(err.body.error)){
			let event = new Event("hpay-auth-expired");
			window.dispatchEvent(event);
		}
									
        console.log(err)
      });
  }
  useEffect(() => {
    loadData("defalut");
    // eslint-disable-next-line  
  }, [])

  return (
    <Page title="Company | Holest">
      <Snackbar open={AlertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={AlertClose}>
        <Alert onClose={AlertClose} severity={AlertType}>
          {AlertMessage}
        </Alert>
      </Snackbar>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('Company')}
          </Typography>
		  {/*
          <SelectSite reload={loadData} />
		  */}
          <Button
            variant="contained"
            onClick={(e) => NewCompany(e)}
            startIcon={<AddTaskIcon />}
            color="secondary"
          >
            {t('Create a company')}
          </Button>

        </Stack>
        {/* <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductSort />
          </Stack>
        </Stack> */}
		
		{loading ? <div className={"center-child"}><div className={"lds-roller"}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>  : null}
		
        <Grid container spacing={2}>
		  

          {
            Companydata.map((subitem, key) => (
              <Grid item xs={4} paddingRight={2} paddingBottom={5} key={key}>
                <Box sx={{ position: 'relative' }}>
                  <Label
                    variant="filled"
                    color={'info'}
                    sx={{
                      zIndex: 9,
                      top: 16,
                      right: 16,
                      position: 'absolute',
                      textTransform: 'uppercase'
                    }}
                  >
                    {t('Company')}
                  </Label>

                  <Card className='clickable'>

                    <CardMedia
                      component="img"
                      height="200"
					  image={subitem.LogoFile ? (configData.API_URL + "/public/" + subitem.LogoFile) : "/static/default.png"}
                      alt="Company Logo"
					  className="object-fit-contain"
                      onClick={(e) => details(subitem)}
                    />
                    <CardContent>
                      <Typography variant="h4" style={{ justifyContent: "center", display: "flex" }} color="text.secondary">
                        {subitem.Name}
                      </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                      <IconButton aria-label="see details" onClick={(e) => details(subitem)}>
                        <RemoveRedEyeIcon />
                      </IconButton>
					  
					  { subitem.CurrentUserRole != "staff" ?
					  <IconButton aria-label="share" onClick={(e) => ItemDelete(subitem.Id, subitem.Name)}>
                        <DeleteOutlineIcon />
                      </IconButton>: null}
                      
					  <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography variant="body2" color="text.secondary" style={{ display: 'flex', justifyContent: 'left' }}>
                          <Label className='info-line' variant="ghost" >{t('Address')}: {subitem.Address}</Label>
                          
                        </Typography>
                        <Typography variant="body2" color="text.secondary" style={{ display: 'flex', justifyContent: 'left' }}>
                          <Label className='info-line' variant="ghost" >{t('ResNo')}: {subitem.RegNo}</Label>
                          
                        </Typography>
                        <Typography variant="body2" color="text.secondary" style={{ display: 'flex', justifyContent: 'left' }}>
                          <Label className='info-line' variant="ghost" >{t('TaxNo')}: {subitem.TaxNo}</Label>
                          
                        </Typography>
						<Typography variant="body2" color="text.secondary" style={{ display: 'flex', justifyContent: 'left' }}>
                          <Label className='info-line' variant="ghost" >{t('Country')}: {subitem.Country}</Label>
                          
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Box>
              </Grid>
            ))
			}
        </Grid>
      </Container>
    </Page>
  );
}
