import { Container, Stack, Typography, Button } from '@mui/material';
import Page from '../components/Page';
import Iframe from 'react-iframe'
import { fetchUtils } from 'react-admin';
import jsonServerProvider from '../data_provider';
import configData from "../config.json";
import { useEffect, useState } from 'react';
//import SelectSite from './SelectSite'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { concat } from 'lodash-es';

export default function DashboardApp() {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const handleClose = () => {
    setOpen(false);
  };

  const httpClient = (url, options = {}) => {
	if(!sessionStorage.AccessToken || !sessionStorage.UserData){
		let event = new Event("hpay-auth-expired");
		window.dispatchEvent(event);
		return null;
	}
		
	if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json', xcompanyid: sessionStorage.company_id, xsiteid: sessionStorage.site_id  });
    }
    const token = JSON.parse(sessionStorage.AccessToken || "{}").Token;
    options.headers.set('Authorization', `${token}`);
    return fetchUtils.fetchJson(url, options);
  };
  
  const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);
  
  const loadDashboardContext = function(){
	  if(window["dashboard_context_" + sessionStorage.language]){
		  if(!document.getElementById('dashboard_context')){
			setTimeout(loadDashboardContext,1000);  
		  }else
			document.getElementById('dashboard_context').innerHTML = window["dashboard_context_" + sessionStorage.language];
	  }else{
		  fetch("./static/dashboard_" + sessionStorage.language + ".html").then(r => {
			  return r.text()	  
		  }).then(html => {
			  window["dashboard_context_" + sessionStorage.language] = html;
			  if(document.getElementById('dashboard_context'))
				document.getElementById('dashboard_context').innerHTML = html;
		  }).catch(err => {});
	  }
  };
  
  loadDashboardContext();
  document.addEventListener("languange_changed", function(e){
	  loadDashboardContext();
  });
  
  const loadData = (param) => {
	try{  
		dataProvider.getList("hp", { pagination: { page: 1, perPage: 10 }, sort: { field: 'id', order: 'ASC' }, filter: {} }).then(res => {
		  console.log(res)
		  const data = res.data;
		  let sup = [];
		  console.log(data)
		  for (var i = 0; i < data.length; i++) {
			if (data[i].Name === "ticket_support" || data[i].Name === "hp_staff") {
			  const support = data[i].Value.split(",");
			  // sup.concat(support);
			  for (var j = 0; j < support.length; j++) {
				sup.push(support[j]);
			  }
			}
		  }
		  
		}).catch(error => {
			if(error && error.body && error.body.error && /^401 /.test(error.body.error)){
				let event = new Event("hpay-auth-expired");
				window.dispatchEvent(event);
			}
		});
	}catch(ex){
		history.push('/login', { replace: true });
	}
  }
  
  const gocreatecompany = (params) => {
    history.push('/newcompany')
  }

  useEffect(() => {
    loadData("defalut");

    // eslint-disable-next-line  
  }, [])

  return (
    <Page title="Dashboard | Minimal-UI">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t('Welcome!')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('You have no company defined. Please first create a company or wait for the access invite!')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" color="secondary" onClick={(e) => gocreatecompany()}>
            {t('Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">{t('Dashboard')}</Typography>
	      {/*<SelectSite reload={loadData} />*/}
        </Stack>
        <div id="dashboard_context" />
      </Container>
    </Page>
  );
}
