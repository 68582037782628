import { useState, useEffect, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { deviceType, osName, browserName } from 'react-device-detect';


// material
import {
  Card,
  Stack,
  Avatar,
  Button,
  Checkbox,
  Container,
  Typography,
  TextField,
  FormControl,
  IconButton,
  InputAdornment,
  TableContainer, Table, TableRow, TableBody, TableHead, TableCell
} from '@mui/material';
// components
import { Icon } from '@iconify/react';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SendIcon from '@mui/icons-material/Send';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PasswordAppIcon from '@mui/icons-material/Lock';
import FingerprintAppIcon from '@mui/icons-material/Fingerprint';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { fetchUtils } from 'react-admin';
import jsonServerProvider from '../data_provider';
import configData from "../config.json";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { useLocation } from 'react-router';
import queryString from 'query-string';


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function User() {
  const history = useHistory();
  const location = useLocation();
  
  const { t, i18n } = useTranslation();
  
  const [AlertMessage, setAlertMessage] = useState("success");
  const [AlertType, setAlertType] = useState("success");
  const [AlertOpen, setAlertOpen] = useState(false);
  
  const [userData] = useState( JSON.parse( sessionStorage.UserData || "{}" ));
  
  const [Name, setName] = useState(userData.Name);
  const [Telephone, setTelephone] = useState(userData.Tel);
  const [Password, setPassword] = useState("");
  const [RPassword, setRPassword] = useState("");
  const [OPassword, setOPassword] = useState("");
  
  const [WebAuthN, setWebAuthN] = useState("");
  
  const [WebAuthNCredentialList, setWebAuthNCredentialList] = useState([]);
  
  const [webauthnDialogOpen, setWebauthnDialogOpen] = useState(false);
  
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [passwordInputValid, setPasswordInputValid] = useState({valid:null, message:"", rmessage:""});
  
  const [showPassword, setShowPassword] = useState(false);
  
  const [challengeData, setChallengeData] = useState({challenge:"", externalref:""});
  const [verificationUid, setVerificationUid]     = useState("");
  const [externalVerifyUrl, setExternalVerifyUrl] = useState("");
  const [externalQRDataUrl, setExternalQRDataUrl] = useState("");
  const [noWebAuthNWarning, setNoWebAuthNWarning] = useState("");
  
  const [externalDeviceConnected, setExternalDeviceConnected] = useState(false);
  
  const [isChangingPassword,setIsChangingPassword] = useState(false);
  
  const httpClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json', xcompanyid: sessionStorage.company_id, xsiteid: sessionStorage.site_id  });
    }
    const token = JSON.parse(sessionStorage.AccessToken || "{}").Token;
    options.headers.set('Authorization', `${token}`);
    return fetchUtils.fetchJson(url, options);
  };
  const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);
  
  const AlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
    
  const back = (params) => {
        history.push('/app')
  };
  
  const handlePasswordDialogClose = () => {
	  setPasswordDialogOpen(false);
  }; 
  
  const handleWebAuthNDialogClose = () => {
	  setWebauthnDialogOpen(false);
	  if(sessionStorage.addsite){
		  history.push('/sites');
	  }
  };
  
  const doChangePassword = () => {
	  
	  setIsChangingPassword(true);
	  
	  try{
		window.getIOClient().emit("setPassword", {Password, OPassword}, function(resp){
			setIsChangingPassword(false);
			if(resp && resp.status == "OK"){
				setPasswordDialogOpen(false);
				
				setPassword("");
				setRPassword("");
				setOPassword("");
				
				setAlertMessage(t("Password updated") + "!");
				setAlertType("success");
				setAlertOpen(true);
			}else{
				if(resp && (resp.error_code == 406 || resp.error_code == 403)){
					setAlertMessage(t("Error - password refused") + "!");
				}else{
					setAlertMessage(t("Error - in password update request") + "!");
				}
				setAlertType("error");
				setAlertOpen(true);
			}
		});
	  }catch(ex){
		setAlertMessage(t("Error - could not update the password") + "!");
		setAlertType("error");
		setAlertOpen(true);
		setIsChangingPassword(false);
	  }
	  
	  //handlePasswordDialogClose();
	  
  };
  
  const saveUserData = () => {
	  try{
		window.getIOClient().emit("saveUserData", { Name: Name, Tel: Telephone }, function(resp){
			if(resp && resp.status == "OK"){
				setAlertMessage(t("Data updated") + "!");
				setAlertType("success");
				setAlertOpen(true);
				
				let ud = JSON.parse( sessionStorage.UserData || "{}" );
				
				ud.Tel = Telephone;
				ud.Name = Name;
				
				sessionStorage.UserData = JSON.stringify(ud);
				
			}else{
				setAlertMessage(t("Error - could not update the data") + "!");
				setAlertType("error");
				setAlertOpen(true);
			}
		});
	  }catch(ex){
		setAlertMessage(t("Error - could not update the data") + "!");
		setAlertType("error");
		setAlertOpen(true);
	  }
	  
  };
  
  const base64encode = (arrayBuffer) => {
		if (!arrayBuffer || arrayBuffer.length == 0)
			return undefined;
		return btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)));
  };

  const arrayBufferToString = (arrayBuffer) => {
		return String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
  };

  const stringToArrayBuffer = (str) => {
		return Uint8Array.from(str, c => c.charCodeAt(0)).buffer;
  };
  /*
  const handleAddWebAuthNCurrentBrowserTEST = () => {
	  
	let verificatinonuid = osName + "-" + browserName + "-" + (new Date()).toISOString().substring(0,22).replace(/[^\d]/g,'');
	  
	  try{
		window.getIOClient().emit("getWebauthNAssertChallenge", { 
			
		}, function(resp){
			if(resp && resp.challenge){
				
				
				let getAssertionOptions = {
					//specifies which credential IDs are allowed to authenticate the user
					//if empty, any credential can authenticate the users
					allowCredentials: resp.IDS.reverse().map(credentialId => { return {
															type: "public-key",
															id: Uint8Array.from(atob(credentialId), c=>c.charCodeAt(0)).buffer
														 }}),
					//an opaque challenge that the authenticator signs over
					challenge: stringToArrayBuffer(resp.challenge),
					//Since Edge shows UI, it is better to select larger timeout values
					timeout: 180000
				};
				
				return navigator.credentials.get({
					publicKey: getAssertionOptions
				}).then(rawAssertion => {
					
					let assertion = {
						id: base64encode(rawAssertion.rawId),
						clientDataJSON: arrayBufferToString(rawAssertion.response.clientDataJSON),
						userHandle: base64encode(rawAssertion.response.userHandle),
						signature: base64encode(rawAssertion.response.signature),
						authenticatorData: base64encode(rawAssertion.response.authenticatorData)
					};
					
					window.getIOClient().emit("webauthAssert", {verificationuid: verificatinonuid, assertion: assertion}, function(assert_resp){
						if(assert_resp.error){
							alert("ERROR");
						}else{
							alert("SUCCESS");
						}
					});
				});
			}else{
				if(resp && resp.error_code == 406){
					setAlertMessage(t("Error - refused") + "!");
				}else{
					setAlertMessage(t("Error - in request") + "!");
				}
				setAlertType("error");
				setAlertOpen(true);
			}
		});
		
		
	  }catch(ex){
		setAlertMessage(t("Error - could not obtain credential parameters") + "!");
		setAlertType("error");
		setAlertOpen(true);
	  }

	
  };
  */
  
  const handleWebAuthNRemoveUid = (uid) => {
	  
	  confirmAlert({
		  title: t('Remove biometric/webauthn credential') + "?",
		  message: t('Please confirm you want remove credential') + " '" + uid + "'." ,
		  buttons: [
			{
			  label: t('Remove'),
			  onClick: () => {
				 if(window.getIOClient && window.getIOClient().connected){
						window.getIOClient().emit("listWebAuthNCredentials", {delete:[uid]}, function(resp){
							if(resp && resp.credentails){
								setWebAuthNCredentialList(resp.credentails);
							}
						});	
					} 
			  }
			},
			{
			  label: t('Cancel'),
			  onClick: () => {}
			}
		  ]
		});
  };
  
  const aquireChallengeForCreate = (callback, error_callback) => {
	  let verificatinonuid = osName + "-" + browserName + "-" + (new Date()).toISOString().substring(0,22).replace(/[^\d]/g,'');
	  setVerificationUid(verificatinonuid);
	  try{
		  
		window.getIOClient().on("onExternalWebauthStoreCredentials", function(data){
			try{
				setWebAuthNCredentialList(data.credentails);
				handleWebAuthNDialogClose();
				
				setAlertMessage(t("Credentials over external device added successfully") + "!");
				setAlertType("success");
				setAlertOpen(true);

			}catch(ex){
				//
			}
		});
		
		window.getIOClient().on("onExternalRefConnected", function(data){
			try{
				if(data.ref == challengeData.externalref){
					setExternalDeviceConnected(true);
				}
			}catch(ex){
				//
			}
		});
		
		window.getIOClient().on("onExternalRefDisconnect", function(data){
			try{
				if(data.ref == challengeData.externalref){
					setExternalDeviceConnected(false);
				}
			}catch(ex){
				//
			}
		});
		
		window.getIOClient().emit("getWebauthNChallenge", { 
			verificationuid: verificatinonuid,
			createexternalref: true
		}, function(resp){
			if(resp && resp.challenge){
				challengeData.challenge   = resp.challenge;
				challengeData.externalref = resp.externalref;
				setChallengeData(challengeData);
				if(callback){
					callback();
				}
			}else{
				if(resp && resp.error_code == 406){
					setAlertMessage(t("Error - refused") + "!");
				}else{
					setAlertMessage(t("Error - in request") + "!");
				}
				setAlertType("error");
				setAlertOpen(true);
				
				if(error_callback){
					error_callback();
				}
			}
		});
	  }catch(ex){
		setAlertMessage(t("Error - could not obtain credential parameters") + "!");
		setAlertType("error");
		setAlertOpen(true);
		
		if(error_callback){
			error_callback();
		}
	  }
  }
  
  const handleAddWebAuthNCurrentBrowser = () => {
	  
		let createCredentialDefaultArgs = {
		  publicKey: {
			rp: {
			  name: t("HolestPay") + (/sandbox/.test(configData.API_URL) ? " sandbox" : "") + deviceType + "-" + osName + "-" + browserName,
			  icon: "https://pay.holest.com/static/logo.png"
			},
			user: {
			  id: stringToArrayBuffer(String(parseInt(userData.id))),
			  name: userData.Email,
			  displayName: userData.Name,
			  icon: "https://pay.holest.com/static/logo.png"
			},
			pubKeyCredParams: [
			  {
				alg: -7,
				type: "public-key",
			  },
			  {
				alg: -257,
				type: "public-key",
			  }
			],
			authenticatorSelection: {
				//Select authenticators that support username-less flows
				//requireResidentKey: true,//WILL SHOW QR
				//Select authenticators that have a second factor (e.g. PIN, Bio)
				//userVerification: "required",
				//authenticatorAttachment: "platform"
			},
			excludeCredentials: [],
			attestation: "none",
			timeout: 360000,
			challenge: stringToArrayBuffer(challengeData.challenge)
		  },
		};
		
		navigator.credentials
		  .create(createCredentialDefaultArgs)
		  .then((cred) => {
			
			if(cred.response && cred.response.attestationObject){
				let attestation = {
					id: base64encode(cred.rawId),
					clientDataJSON: arrayBufferToString(cred.response.clientDataJSON),
					attestationObject: base64encode(cred.response.attestationObject)
				};
				
				window.getIOClient().emit("webauthStoreCredentials", { 
					verificationuid: verificationUid,
					verificationdata: {
						deviceType, 
						osName, 
						browserName
					},
					attestation: attestation
				}, function(store_resp){
					setWebAuthNCredentialList(store_resp.credentails);
					handleWebAuthNDialogClose();
				});
			}else{
				setAlertMessage(t("Could not obtain credentials!"));
				setAlertType("error");
				setAlertOpen(true);
			}
		  }).catch(err => {
			  setAlertMessage(t("Could not obtain credentials!"));
			  setAlertType("error");
			  setAlertOpen(true);
		  });
  };
  
  const validatePasswordInput = (pass, rpass, opass) => {
	  passwordInputValid.current_pass_valid   = null;
	  passwordInputValid.current_pass_message = null;
	  
	  if(!opass){
		  passwordInputValid.current_pass_valid = false;
		  passwordInputValid.current_pass_message = t("Enter current password"); 
	  }
	  
	  if(pass){
		  passwordInputValid.rmessage = "";
		  
		  if(pass.length < 8){
			  passwordInputValid.valid = false;
			  passwordInputValid.message = t("Please enter at least 8 characters");
			  
		  }else if(!/\d/.test(pass)){
			  passwordInputValid.valid = false;
			  passwordInputValid.message = t("Please enter at least 1 number");
			  
		  }else if(!/[a-z]/.test(pass)){
			  passwordInputValid.valid = false;
			  passwordInputValid.message = t("Please enter at least 1 lowercase letter");
			  
		  }else if(!/[A-Z]/.test(pass)){
			  passwordInputValid.valid = false;
			  passwordInputValid.message = t("Please enter at least 1 uppercase letter");
			  
		  }else if(!/[\`|\~|\!|\@|\$|\%|\^|\&|\*|\(|\)|\+|\-|\=|\{|\}|\[|\]|\:|\;|\"|\'|\||\\|\/|\<|\>|\,|\.|\?]/.test(pass)){
			  passwordInputValid.valid = false;
			  passwordInputValid.message = t("Please enter at least 1 special character");
			  
		  }else if(pass !== rpass){
			  passwordInputValid.valid = false;
			  passwordInputValid.message = "";
			  passwordInputValid.rmessage = t("Passwords do not match");
		  }else{
			  passwordInputValid.valid = true;
			  passwordInputValid.message = "";
			  passwordInputValid.rmessage = "";
		  }
	  }else{
		  passwordInputValid.valid = null;
		  passwordInputValid.message = "";
		  passwordInputValid.rmessage = "";
	  }
	  
	  if(passwordInputValid.current_pass_valid === false){
		  passwordInputValid.valid = false;
	  }
	  
	  setPasswordInputValid(passwordInputValid);
	  
	  return passwordInputValid.valid;
  };
  
  const loadCredList = () => {
	  try{
			if(window.getIOClient && window.getIOClient().connected){
				window.getIOClient().emit("listWebAuthNCredentials", {}, function(resp){
					if(resp && resp.credentails){
						if(resp.credentails.length){
							setNoWebAuthNWarning(t("No biometric/webauthn credetials exists. When identity verification is required HolestPay will revert method of sending you 6 digit security code over your e-mail. It's recommended thet you use more modern and secure biometric/webauthn methods since they don't involve trasmition of security parameters in clear form over channels that don't guarantee absolute security."));
						}
						setWebAuthNCredentialList(resp.credentails);
					}
					
					if(queryString.parse(location.search).createwebauthn){
		  
						  setTimeout(function(){
							  
								let btn = document.getElementById('cmdCreateWebauthn') || {};
								btn.disabled = true;
								
								setExternalDeviceConnected(false);
								aquireChallengeForCreate(function(){
									
									let url = window.location.origin + "/authn/c/" + challengeData.externalref + "/" + i18n.language;
									setExternalVerifyUrl(url);
									
									window.QRCode.toDataURL(url, {width:128}).then(d => {
										setExternalQRDataUrl(d);
									});
									
									setWebauthnDialogOpen(true); 
									btn.disabled = false;
									
								}, function(){
									
									btn.disabled = false;
								});
							  
						  },150);
					  }
					
					
				});	
			}else{
				setTimeout(loadCredList,1000);
			}
		}catch(ex){
			
		}
  };
  
  const emptyIfNull = (val) => {
		if(val === null || val === undefined)
			return "";
		return val;
	};
  
  useEffect(() => {
	  
      loadCredList();  
	  
  }, []);

  return (
    <Page title="User | Minimal-UI">
	  <Snackbar open={AlertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={AlertClose}>
        <Alert onClose={AlertClose} severity={AlertType} sx={{ width: '100%' }}>
          {AlertMessage}
        </Alert>
      </Snackbar>
	  
	  <Dialog open={passwordDialogOpen} onClose={handlePasswordDialogClose} fullWidth={true} maxWidth="sm">
            <DialogTitle>{t("Change password")}</DialogTitle>
            <DialogContent>
			
              <DialogContentText>
                {t("Pick a new password of at least 8 characters, at least one uppercase letter and at least one number")}
              </DialogContentText>
			  
			  <br/>
			  
			  <TextField
                autoFocus
                margin="dense"
                label={t("Current password")}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                variant="standard"
				InputProps={{
				  endAdornment: (
					<InputAdornment position="end">
					  <IconButton tabIndex={-1} onClick={(e) => { setShowPassword( !showPassword ) }} edge="end">
						<Icon icon={showPassword ? eyeFill : eyeOffFill} />
					  </IconButton>
					</InputAdornment>
				  )
				}}
				error={!!passwordInputValid.current_pass_message}
				helperText={passwordInputValid.current_pass_message}
                value={OPassword}
				onChange={(e) => {setOPassword(e.target.value); validatePasswordInput(Password, RPassword, e.target.value);}}
              />
			  
			  <TextField
                autoFocus
                margin="dense"
                label={t("New password")}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                variant="standard"
				InputProps={{
				  endAdornment: (
					<InputAdornment position="end">
					  <IconButton tabIndex={-1} onClick={(e) => { setShowPassword( !showPassword ) }} edge="end">
						<Icon icon={showPassword ? eyeFill : eyeOffFill} />
					  </IconButton>
					</InputAdornment>
				  )
				}}
				error={!!passwordInputValid.rmessage}
				helperText={passwordInputValid.message}
                value={Password}
				onChange={(e) => {setPassword(e.target.value); validatePasswordInput(e.target.value, RPassword, OPassword);}}
              />
			  
			  <TextField
                margin="dense"
                label={t("Repeat new password")}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                variant="standard"
				InputProps={{
				  endAdornment: (
					<InputAdornment position="end">
					  <IconButton tabIndex={-1} onClick={(e) => { setShowPassword( !showPassword ) }} edge="end">
						<Icon icon={showPassword ? eyeFill : eyeOffFill} />
					  </IconButton>
					</InputAdornment>
				  )
				}}
				error={passwordInputValid.valid === false}
				helperText={passwordInputValid.rmessage}
                value={RPassword}
				onChange={(e) => {setRPassword(e.target.value); validatePasswordInput(Password, e.target.value, OPassword);}}
              />
			  
            </DialogContent>
            <DialogActions>
              <Button disabled={passwordInputValid.valid !== true || isChangingPassword} onClick={(e) => doChangePassword()}>{t("Change password")}</Button>
              <Button onClick={handlePasswordDialogClose}>{t("Cancel")}</Button>
            </DialogActions>
	  </Dialog>
	  
	  
	  <Dialog open={webauthnDialogOpen} onClose={handleWebAuthNDialogClose} fullWidth={true} maxWidth="sm">
            <DialogTitle>{t("Add Biometric/WebAuthN Account Verification")}</DialogTitle>
            <DialogContent>
			
              <Typography variant="p" style={{fontSize:"70%", display:"inline-block", lineHeight:"130%"}} gutterBottom>
					{t("Face-Id and fingerprint are biometric credentials and they provide maximal level of reliability for in the process of your identity verification. It's recommended that you use biometric methods when available. Every browser, even on old PC/phone, will enable you to use basic PIN verification.")}
			  </Typography>
			  
			  <DialogContentText>
                {t("Verification device selection")}:
              </DialogContentText>
			  <br/>
			  
			  <Stack direction="row" justifyContent="space-around"  style={{display:"flex"}} alignItems="center"> 
			  
				  <Button style={{maxWidth:"40%"}}
						onClick={(e) => handleAddWebAuthNCurrentBrowser()}
						startIcon={<FingerprintAppIcon />}
						type="submit"
						color="secondary"
						variant="contained"
					>
						{t("Use current browser")}
				  </Button>
				  
				  <span>{t("OR")}</span>
				  
				  <Stack direction="column"  style={{maxWidth:"40%"}}>
					<h6>{t("Scan this QR code with your mobile phone in order of using your phone as external verification device")}</h6>
					<div className="webauthn-qr-area" style={{ 
						display:"inline-block", 
						width:"128px", 
						height:"128px"
						}}>
						
						{!externalDeviceConnected ?
						<img style={{width:"128px", height:"128px"}} src={externalQRDataUrl} /> :
						<div className="lds-ripple"><div></div><div></div></div> }
						
					</div>
					<Typography variant="p" style={{fontSize:"65%"}} gutterBottom>
					  {t("Equivalent url")}:<br/>{externalVerifyUrl}
					</Typography>
				  </Stack>
				  
			  </Stack>
			  <Typography variant="p" style={{fontSize:"70%", display:"inline-block", lineHeight:"130%"}} gutterBottom>
			   {t("DISCLAMER")}:<br/> HOLEST E-COMMERCE D.O.O. <b>{t("does not store or transmit")}</b> {t("any of your biometric data like Face-Id or Fingerprint. This data is contained in your device OS, and process of verification itslelf is done by your device/PC. Upon successful verification your device will inform our system that your identity is confirmed by providing matching cryptographic signatures.")}
			  </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleWebAuthNDialogClose}>{t("Cancel")}</Button>
            </DialogActions>
	  </Dialog>
	  
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
			{t("Account settings")}
          </Typography>
		  
		  <Button
					variant="dark"
					onClick={(e) => back()}
					startIcon={<ExitToAppIcon />}
					color="secondary"
				>
					{t("Back to dashboard...")}
			</Button>
        </Stack>

        <Card>
          <Scrollbar>
		   
		   <Stack direction="column" justifyContent="space-between" mb={5} padding={5} spacing={2}>
                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={2} paddingTop={2}>
				<TextField 
					fullWidth
					disabled
					label={t("Email")}
					required={true}
					value={userData.Email}
					onChange={(e) => {
						//
					}}
				/>
				
				<TextField 
					fullWidth
					label={t("Name")}
					required={true}
					value={emptyIfNull(Name)}
					onChange={(e) => {
						setName(e.target.value);
					}}
				/>
				
				<TextField 
					fullWidth
					label={t("Telephone")}
					required={true}
					value={emptyIfNull(Telephone)}
					onChange={(e) => {
						setTelephone(e.target.value);
					}}
				/>
				<Button
					fullWidth
					size="large"
					type="submit"
					color="secondary"
					endIcon={<SendIcon />}
					variant="contained"
					onClick={(e) => saveUserData()}
				>
					{t("Save")}
				</Button>
				
			</Stack> 
				
				
			<Stack direction={{ xs: 'row', sm: 'row' }} spacing={2} paddingTop={5}>
				<Button
					fullWidth
					size="small"
					type="submit"
					color="secondary"
					id="cmdCreateWebauthn"
					endIcon={<FingerprintAppIcon />}
					variant="contained"
					onClick={(e)=>{ 
						let btn = e.target;
					    btn.disabled = true;
						
						setExternalDeviceConnected(false);
						aquireChallengeForCreate(function(){
							
							let url = window.location.origin + "/authn/c/" + challengeData.externalref + "/" + i18n.language;
							setExternalVerifyUrl(url);
							
							window.QRCode.toDataURL(url, {width:128}).then(d => {
								setExternalQRDataUrl(d);
							});
							
							setWebauthnDialogOpen(true); 
							btn.disabled = false;
							
						}, function(){
							
							btn.disabled = false;
						});
					
					}}
				>
					{t("Add Biometric (Fingerprint, Face-Id or PIN) verification")}
				</Button>
				<Button
					fullWidth
					size="small"
					type="submit"
					color="secondary"
					endIcon={<PasswordAppIcon />}
					variant="contained"
					onClick={(e) => setPasswordDialogOpen(true)}
				>
					{t("Change password")}
				</Button>
				
			</Stack>
			
			<Stack paddingTop={3}>
				<h4>{t("Biometric/WebAuthN credentials")}:</h4>
				<TableContainer sx={{ minWidth: 800 }}>
              <Table>
            	<TableHead>
					<TableRow>
						<TableCell style={{fontSize:"11px"}}>{t("UID")}</TableCell> 
						<TableCell style={{fontSize:"11px"}}>{t("Device type")}</TableCell> 
						<TableCell style={{fontSize:"11px"}}>{t("OS")}</TableCell> 
						<TableCell style={{fontSize:"11px"}}>{t("Browser")}</TableCell> 
						<TableCell style={{fontSize:"11px"}}></TableCell> 
					</TableRow>
				</TableHead>
			    <TableBody>
                  {WebAuthNCredentialList
				    .map((row, key) => {
                      return (
                        <TableRow
                          hover
                          key={key}
                          tabIndex={-1}
                          role="checkbox"
                          //selected={isItemSelected}
                          //aria-checked={isItemSelected}
						  className='clickable'
                        >
                          
						  <TableCell style={{fontSize:"11px"}} component="th" scope="row">
                           {row.uid}
                          </TableCell>
                          <TableCell style={{fontSize:"11px"}} align="left" >{row.deviceType}</TableCell>
						  <TableCell style={{fontSize:"11px"}} align="left" >{row.osName}</TableCell>
						  <TableCell style={{fontSize:"11px"}} align="left" >{row.browserName}</TableCell>
						  <TableCell style={{fontSize:"11px"}} align="right" >
						  
						  <DeleteForeverIcon onClick={(e) => handleWebAuthNRemoveUid(row.uid)} />
						  
						  </TableCell>

                        </TableRow>
                      );
                    })}
                  
				  {!WebAuthNCredentialList.length && (
                    <TableRow>
                      <TableCell colSpan={6} >
					  <Typography variant="p" style={{fontSize:"90%", display:"inline-block", lineHeight:"130%"}} gutterBottom>
						{noWebAuthNWarning}
					  </Typography>	
					  </TableCell>
                    </TableRow>
                  )}
		        </TableBody>
		      </Table>
            </TableContainer>
			
			</Stack>
				
			
			
		   </Stack>
				
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
