import SessionControl from './SessionControl';
import configData from "../config.json";
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useCookies } from 'react-cookie';
import common from "../common.js";

export default function Wait() {
	const [cookies, setCookie, removeCookie] = useCookies(['hpayremember','hpayrememberexp']);
	const {hpbtoa, hpatob} = common;
	const location = useLocation();
    const history = useHistory();
	
	if(!sessionStorage.AccessToken){
		if(cookies["hpayremember"]){
			try{
				let authorization = JSON.parse(hpatob(cookies["hpayremember"]));
				fetch(configData.API_URL + "me", {
				  method: 'GET',
				  headers:{
					  Authorization: authorization.authToken.Token
				  }
				}).then(rawresp => {
					return rawresp.json();
				}).then(resp => {
					try{
						if(resp.id && resp.Email){
							sessionStorage.AccessToken = JSON.stringify(authorization.authToken);
							sessionStorage.UserData    = JSON.stringify(authorization.user);
							
							if(sessionStorage.addsite){
								history.push('/sites');
							}else{
								if(sessionStorage.afterloginurl){
									history.push(sessionStorage.afterloginurl);
									delete sessionStorage.afterloginurl;
								}else{
									history.push('/app');	
								}
							}
						}else{
							delete sessionStorage.AccessToken;
							removeCookie("hpayremember");
							removeCookie("hpayrememberexp");
							history.push('/login', { replace: true });
						}
					}catch(ex){
						delete sessionStorage.AccessToken;
						removeCookie("hpayremember");
						removeCookie("hpayrememberexp");
						history.push('/login', { replace: true });
					}
				}).catch(err => {
					delete sessionStorage.AccessToken;
					removeCookie("hpayremember");
					removeCookie("hpayrememberexp");
					history.push('/login', { replace: true });
				});
			}catch(ex){
				delete sessionStorage.AccessToken;
				removeCookie("hpayremember");
				removeCookie("hpayrememberexp");
				history.push('/login', { replace: true });
			}
		}else{
			delete sessionStorage.AccessToken;
			removeCookie("hpayremember");
			removeCookie("hpayrememberexp");
			history.push('/login', { replace: true }); 
		}
	}else{
		
		fetch(configData.API_URL + "me", {
		  method: 'GET',
		  headers:{
			  Authorization: JSON.parse(sessionStorage.AccessToken || "{}").Token
		  }
		}).then(rawresp => {
			return rawresp.json();
		}).then(resp => {
			try{
				if(!resp.id || !resp.Email){
					delete sessionStorage.AccessToken;
					removeCookie("hpayremember");
					removeCookie("hpayrememberexp");
					history.push('/login', { replace: true });
				}else if(sessionStorage.UserData.indexOf(resp.Email) === -1){
					delete sessionStorage.AccessToken;
					removeCookie("hpayremember");
					removeCookie("hpayrememberexp");
					history.push('/login', { replace: true });
				}else{
					if(sessionStorage.addsite){
						history.push('/sites');
					}else{
						if(sessionStorage.afterloginurl){
							history.push(sessionStorage.afterloginurl);
							delete sessionStorage.afterloginurl;
						}else{
							history.push('/app');	
						}
					}
				}
			}catch(ex){
				delete sessionStorage.AccessToken;
				removeCookie("hpayremember");
				removeCookie("hpayrememberexp");
				history.push('/login', { replace: true });
			}
		}).catch(err => {
			delete sessionStorage.AccessToken;
			removeCookie("hpayremember");
			removeCookie("hpayrememberexp");
			history.push('/login', { replace: true });
		});
	}
		
	return (
		<div>
			<SessionControl />
			<div style={{"height": "90vh"}} className={"center-child"}><div className={"lds-roller"}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
		</div>
	)
}