import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
// import personFill from '@iconify/icons-eva/person-fill';
// import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
import MenuPopover from '../../components/MenuPopover';
import configData from "../../config.json";
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

export default function AccountPopover() {
  const [cookies, setCookie, removeCookie] = useCookies(['hpayremember','hpayrememberexp']);
  
  const anchorRef = useRef(null);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const MENU_OPTIONS = [
    {
      label: t('Home'),
      icon: homeFill,
      linkTo: '/app'
    }
  ];
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const logout = async (params) => {
	delete window.HPAYCompanies;  
	
	removeCookie("hpayremember");
	removeCookie("hpayrememberexp");
	
	if(!sessionStorage.AccessToken){
		history.push('/login', { replace: true });
	}
	
	fetch(configData.API_URL + "logout", {
				  method: 'POST',
				  headers:{
					  Authorization: JSON.parse(sessionStorage.AccessToken || "{}").Token
				  },
				  body: ""
				}).then(rawresp => {
					return rawresp.json();
				}).then(resp => {
					sessionStorage.clear();
					history.push('/login', { replace: true });
				}).catch(err => {
					sessionStorage.clear();
					history.push('/login', { replace: true });
				});
  }

  useEffect(() => {
        window.addEventListener("hpay-auth-expired", logout, false);
		return () => {
			window.removeEventListener("hpay-auth-expired", logout, false);
		};
    }, [])
	
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={"/static/mock-images/avatars/avatar_default.jpg"} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 260 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {sessionStorage.UserData ? JSON.parse(sessionStorage.UserData || "{}").Name : ""}
          </Typography>
          <Button 
				color={"grey"}
				size="small"
				type="reset"
				variant="contained"
				onClick={(e) => { setOpen(false); history.push('/user'); }}
				>
            {sessionStorage.UserData ? JSON.parse(sessionStorage.UserData || "{}").Email : ""}
			</Button>
          
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={(e) => logout(e)}>
            {t("Logout")}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
