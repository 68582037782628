import { useState, useEffect, forwardRef } from 'react';
// import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import configData from "../../config.json";
import { io } from 'socket.io-client';

// ----------------------------------------------------------------------

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import SessionControl from './../SessionControl';
import GlobalElements from './../GlobalElements';
import { useCookies } from 'react-cookie';
import countries from '../../countries.json';
import currencies from '../../currencies.json';
import md5 from 'md5';

import common from "../../common.js";


const APP_BAR_MOBILE  = 64;
const APP_BAR_DESKTOP = 92;
const SOCKET_CLIENT = { socket: null };

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
  
}));

// ----------------------------------------------------------------------
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function DashboardLayout({ children }) {
  const {hpbtoa, hpatob} = common;	
  const [cookies, setCookie, removeCookie] = useCookies(['hpayremember','hpayrememberexp']);
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  
  const [open, setOpen] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");
  const [AlertType, setAlertType]       = useState("info");
  const [AlertOpen, setAlertOpen]       = useState(false);
  const [initDone, setInitDone]         = useState(false); 
 
  
  window.COUNTRIES = countries;
  window.CURRENCIES = currencies;
  
  //THIS METHOOD IS SPECIFIC
  const httpClient = (url, options = {}) => {
	  
	if(!sessionStorage.AccessToken || !sessionStorage.UserData){
		let event = new Event("hpay-auth-expired");
		window.dispatchEvent(event);
		return null;
	}
	/////////////////////////////////////////////////////////////////////////////////////////////////////////
	let xcompanyid = sessionStorage.company_id || "";
	if(!xcompanyid){
		if(/\/company_sites/.test(url)){
			return new Promise((resolve, reject) => resolve({json:[],headers:new Headers({"x-total-count":0}),body:"[]"}));
		}
	}
	/////////////////////////////////////////////////////////////////////////////////////////////////////////  
	if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json', xcompanyid: xcompanyid, xsiteid: sessionStorage.site_id || "" });
    }
	
    const token = JSON.parse(sessionStorage.AccessToken || "{}").Token;
    options.headers.set('Authorization', `${token}`);
    
	return fetchUtils.fetchJson(url, options);
  };
  
  try{
	  if(/\?/.test(window.location.href)){
		  let qs = {};
		  
		  window.location.href.split("?").forEach(l => {
			 try{
				 let nv = l.split("=");
				 let name = nv[0];
				 nv.splice(0,1);
				 qs[name] = decodeURIComponent(nv.join("="));
			 }catch(pex){
				 console.error(pex);
			 }
		  });
		  if(qs.pos){
			  sessionStorage.selected_pos = qs.pos;
		  }
	  }
  }catch(ex){
	  console.error(ex);
  }
  
  const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);
  if(!sessionStorage.AccessToken){
	sessionStorage.afterloginurl = location.pathname;
	history.push('/wait', { replace: true });
  }
  
  const handleClose = function(){
	  setAlertOpen(false);
  }
  
  const beep_snd = new Audio(configData.BEEP);  
  
  const forceLoadSitesForCurrentCompany = () => {
	  loadSitesForCompany(sessionStorage.company_id, true);
  };
  
  const loadSitesForCompany = (company_id, force, all) => {
	  if(!window.HPAYCompanies){
		  return;
	  }
	  
	  if(!all && !window.HPAYCompanies[company_id]){
		  return;
	  }
	  
	  if(!all){
		  let last_sid = localStorage["hpay_last_site_id"] || "";
		  
	  	  if(!force && window.HPAYCompanies[company_id].HPAYSites){
			  if(!sessionStorage.site_id || (sessionStorage.site_id && !window.HPAYCompanies[company_id].HPAYSites[sessionStorage.site_id])){
				  let found_site = null;
				  
				  if(Object.keys(window.HPAYCompanies[company_id].HPAYSites).length == 1){
					  found_site = Object.values(window.HPAYCompanies[company_id].HPAYSites)[0];
				  }else if(localStorage["hpay_last_site_id"]){
					  if(window.HPAYCompanies[company_id].HPAYSites[localStorage["hpay_last_site_id"]]){
						  found_site = window.HPAYCompanies[company_id].HPAYSites[localStorage["hpay_last_site_id"]];
					  }
				  }
				  
				  if(!found_site && Object.keys(window.HPAYCompanies[company_id].HPAYSites).length){
					  found_site = Object.values(window.HPAYCompanies[company_id].HPAYSites)[0];
				  }
				  
				  if(found_site){
					  sessionStorage.CurrentSite = JSON.stringify({ id: found_site.id, name:found_site.Url });
					  sessionStorage.site_id = found_site.id;
					  localStorage.LastSite = sessionStorage.CurrentSite;
					  localStorage["hpay_last_site_id"] = sessionStorage.site_id;
				  }else{
					  sessionStorage.CurrentSite = JSON.stringify({ id: "", name: t('--not selected--') })
					  sessionStorage.site_id = "";
					  localStorage.LastSite = sessionStorage.CurrentSite;
					  localStorage["hpay_last_site_id"] = "";
				  }
				  
				  if(last_sid != localStorage["hpay_last_site_id"] || sessionStorage.force_site_changed){
					  let event = new Event("hpay-site-changed");
					  window.dispatchEvent(event);
					  delete sessionStorage.force_site_changed;
				  }
				  
			  }else{
				  
				  if(!window.HPAYCompanies[company_id].HPAYSites[sessionStorage.site_id]){
					  sessionStorage.CurrentSite = JSON.stringify({ id: "", name: t('--not selected--') })
					  sessionStorage.site_id = "";
					  localStorage.LastSite = sessionStorage.CurrentSite;
					  localStorage["hpay_last_site_id"] = "";
				  }else{
					  let found_site = window.HPAYCompanies[company_id].HPAYSites[sessionStorage.site_id];
					  sessionStorage.CurrentSite = JSON.stringify({ id: found_site.id, name:found_site.Url });
					  sessionStorage.site_id = found_site.id;
					  localStorage.LastSite = sessionStorage.CurrentSite;
					  localStorage["hpay_last_site_id"] = sessionStorage.site_id;
				  }
				  
				  if(last_sid != localStorage["hpay_last_site_id"] || sessionStorage.force_site_changed){
					  let event = new Event("hpay-site-changed");
					  window.dispatchEvent(event);
					  delete sessionStorage.force_site_changed;
				  }
			  }
			  
			  let event = new Event("hpay-sites-loaded");
			  window.dispatchEvent(event);
			  
			  if(sessionStorage.gotolink){
					checkGoToLink();
				}
			  
			  return;
		  }else {
			  let null_site = false;
			  if(!window.HPAYCompanies[company_id].HPAYSites){
				null_site = true;
			  }else if(!Object.keys(window.HPAYCompanies[company_id].HPAYSites).length){
				null_site = true;  
			  }
			  if(null_site){
				  sessionStorage.CurrentSite = JSON.stringify({ id: "", name: t('--not selected--') })
				  sessionStorage.site_id = "";
				  localStorage.LastSite = sessionStorage.CurrentSite;
				  localStorage["hpay_last_site_id"] = "";
				  
				  if(last_sid != localStorage["hpay_last_site_id"]){
					window.dispatchEvent(new Event("hpay-site-changed"));
				  }
				  
				  window.dispatchEvent(new Event("hpay-sites-loaded"));
			  }
		  }
	  }
	  
	  sessionStorage.__loading_user_company_sites = 1;
	  dataProvider.getList("company_sites", { pagination: { page: 1, perPage: 9999 }, sort: { field: 'url', order: 'ASC' }, filter: all ? {} : { CompanyId: company_id } })
        .then(response => {
			sessionStorage.__loading_user_company_sites = 0;
			
			if(!window.HPAYCompanies){
			  return;
		    }
			
			if(response.data){
				for(let ind in response.data){
					if(response.data.hasOwnProperty(ind)){
						if(response.data[ind].CompanyId){
							let cid = response.data[ind].CompanyId;
							if(window.HPAYCompanies[cid]){
								if(!window.HPAYCompanies[cid].HPAYSites)
									window.HPAYCompanies[cid].HPAYSites = {};
								window.HPAYCompanies[cid].HPAYSites[response.data[ind].id] = response.data[ind];
								
								if(sessionStorage.selected_pos == response.data[ind].id || sessionStorage.selected_pos == response.data[ind].MerchantsiteUid){
									  let company = window.HPAYCompanies[cid];
									  sessionStorage.CurrentCompany  = JSON.stringify({ id: company.id, name: company.Name });
									  sessionStorage.company_id      = company.id;
									  let role = "staff";
									  if(company.UserCompanies && company.UserCompanies[0] && company.UserCompanies[0].Role){
										role = company.UserCompanies[0].Role;
									  }
									  sessionStorage.CurrentUserRole = role;
									  company_id = cid;
									  
									  let found = response.data[ind];
									  sessionStorage.CurrentSite = JSON.stringify({ id: found.id, name:found.Url });
									  sessionStorage.site_id = found.id;
									  localStorage.LastSite = sessionStorage.CurrentSite;
									  localStorage["hpay_last_site_id"] = sessionStorage.site_id;
									  
									  sessionStorage.force_site_changed = 1;
									  
									  delete sessionStorage.selected_pos;
								}
							}
						}
					}
				}
			}
			
			if(all){
				delete sessionStorage.selected_pos;
				setInitDone(true);
			}
			
			if(company_id && all){
				loadSitesForCompany(company_id);
			}
			
		}).catch(error => {
			setInitDone(true);
			
			sessionStorage.__loading_user_company_sites = 0;
			 if(error && error.body && error.body.error && /^401 /.test(error.body.error)){
				let event = new Event("hpay-auth-expired");
				window.dispatchEvent(event);
			 }
			 console.log(error)
		});
  };
  
  const onCompanyChange = () => {
	  loadSitesForCompany(sessionStorage.company_id);	
  };
  
  const loadUserEntitiesForce = () => {
	  loadUserEntities(true);
  };
  
  const loadUserEntities = (force, is_init) => {
	  if(force){
		  window.HPAYCompanies = null;
	  } 
	  
	  if(!window.HPAYCompanies){
		window.HPAYCompanies = {};
		
		sessionStorage.__loading_user_companies = 1;
		
		dataProvider.getList('companies', {
		  pagination: { page: 1, perPage: 9999 },
		  sort: { field: 'name', order: 'ASC' },
		  filter: {  }
		})
		  .then(response => {
			sessionStorage.__loading_user_companies = 0;  
			
			let count = 0;
			for(let ind in response.data){
				if(response.data.hasOwnProperty(ind)){
					let csites = null;
					if(window.HPAYCompanies[response.data[ind].id]){
						if(window.HPAYCompanies[response.data[ind].id].HPAYSites){
							csites = window.HPAYCompanies[response.data[ind].id].HPAYSites;
						}
					}
					
					window.HPAYCompanies[response.data[ind].id] = response.data[ind];
					count++;
					if(csites){
						window.HPAYCompanies[response.data[ind].id].HPAYSites = csites;
					}
				}
			}
			
			if(Object.values(response.data).length == 1){
				sessionStorage.company_id = Object.values(response.data)[0].id; 
			}
			
			if(count == 1){
				sessionStorage.company_id = Object.keys(window.HPAYCompanies)[0];
			}
			
			if(!sessionStorage.company_id){
				let found = null;
				
				if(response.data.length == 1){
					found = response.data[0];
				}else if(localStorage["hpay_last_company_id"]){
					if(window.HPAYCompanies[localStorage["hpay_last_company_id"]]){
						found = window.HPAYCompanies[localStorage["hpay_last_company_id"]];
					}
				}
				
				if(found){
					sessionStorage.CurrentCompany  = JSON.stringify({ id: found.id, name: found.Name });
					sessionStorage.company_id      = found.id;
					
					let role = "staff";
					if(found.UserCompanies && found.UserCompanies[0] && found.UserCompanies[0].Role){
						role = found.UserCompanies[0].Role;
					}
					
					sessionStorage.CurrentUserRole = role;
					localStorage.lastCompany = sessionStorage.CurrentCompany;
					localStorage["hpay_last_company_id"] = found.id;
					
					let event = new Event("hpay-company-changed");
					window.dispatchEvent(event);
				}
				
				
			}else{
				
				if(window.HPAYCompanies[sessionStorage.company_id]){
					let found = window.HPAYCompanies[sessionStorage.company_id];
					if(found){
						let role = "staff";
						if(found.UserCompanies && found.UserCompanies[0] && found.UserCompanies[0].Role){
							role = found.UserCompanies[0].Role;
						}
						
						sessionStorage.CurrentUserRole = role;
						localStorage.lastCompany = sessionStorage.CurrentCompany;
						localStorage["hpay_last_company_id"] = found.id;
						
						sessionStorage.CurrentCompany = JSON.stringify({ id: window.HPAYCompanies[sessionStorage.company_id].id, name: window.HPAYCompanies[sessionStorage.company_id].Name })
					}
					
					if(!is_init)
						loadSitesForCompany(sessionStorage.company_id);
					
				}else{
					
					sessionStorage.CurrentCompany = JSON.stringify({ id: "", name: "--not selected--" })
					sessionStorage.company_id = "";
					sessionStorage.CurrentUserRole = "";
					localStorage.lastCompany = sessionStorage.CurrentCompany;
					localStorage["hpay_last_company_id"] = "";
					
					let event = new Event("hpay-company-changed");
					window.dispatchEvent(event);
				}
			}
			
			if(is_init){
				loadSitesForCompany(sessionStorage.company_id, true, true);
				setInitDone(true);
			}
			
			let event = new Event("hpay-companies-loaded");
			window.dispatchEvent(event);
			
		  }).catch(error => {
			 sessionStorage.__loading_user_companies = 0;  
			 
			 if(error && error.body && error.body.error && /^401 /.test(error.body.error)){
				let event = new Event("hpay-auth-expired");
				window.dispatchEvent(event);
			 }
			 console.log(error)
		  });
	  }else{
		  
		  if(sessionStorage.company_id){
			  if(!sessionStorage.CurrentCompany){
				  if(window.HPAYCompanies[sessionStorage.company_id]){
					  let company = window.HPAYCompanies[sessionStorage.company_id];
					  sessionStorage.CurrentCompany  = JSON.stringify({ id: company.id, name: company.Name });
					  sessionStorage.company_id      = company.id;
					  let role = "staff";
					  if(company.UserCompanies && company.UserCompanies[0] && company.UserCompanies[0].Role){
						role = company.UserCompanies[0].Role;
					  }
					  sessionStorage.CurrentUserRole = role;
				  }
			  }
			  
			  if(sessionStorage.site_id && !sessionStorage.CurrentSite){
				  if(window.HPAYCompanies[sessionStorage.company_id]){
					  if(window.HPAYCompanies[sessionStorage.company_id].HPAYSites){
						  if(window.HPAYCompanies[sessionStorage.company_id].HPAYSites[sessionStorage.site_id]){
							  let site = window.HPAYCompanies[sessionStorage.company_id].HPAYSites[sessionStorage.site_id];
							  sessionStorage.CurrentSite = JSON.stringify({ id: site.id, site:site.Url });
							  sessionStorage.site_id = site.id;
							  localStorage.LastSite = sessionStorage.CurrentSite;
						  }
					  }
				  }
			  }
		  }
		  
		  setInitDone(true);
		  
	  }
  };
  
  function beep() {
    beep_snd.play();
  }
  
  let __hpay_interaction_count = 0;
  
  function interactionCounter(){
	  if(!__hpay_interaction_count){
		  __hpay_interaction_count = 1;
	  }else{
		  __hpay_interaction_count++;
	  }
  }
  
  function extendSession(){
	  if(__hpay_interaction_count && cookies.hpayremember && cookies.hpayrememberexp){
		  if(parseInt(cookies.hpayrememberexp) - 240000 < (new Date()).getTime()){
			  let expires = new Date();
			  expires.setTime(expires.getTime() + 2700000);//45 min
			  setCookie('hpayrememberexp', expires.getTime(), { path: '/',  expires});
			  setCookie('hpayremember', cookies.hpayremember, { path: '/',  expires});
			  __hpay_interaction_count = 0;
		  }
	  }
  }
  
  function checkGoToLink(){
	  
	  if(sessionStorage.company_id){
		  if(!sessionStorage.CurrentCompany){
			  if(window.HPAYCompanies[sessionStorage.company_id]){
				  let company = window.HPAYCompanies[sessionStorage.company_id];
				  sessionStorage.CurrentCompany  = JSON.stringify({ id: company.id, name: company.Name });
				  sessionStorage.company_id      = company.id;
				  
				  let role = "staff";
				  if(company.UserCompanies && company.UserCompanies[0] && company.UserCompanies[0].Role){
					  role = company.UserCompanies[0].Role;
				  }
				  
				  
				  sessionStorage.CurrentUserRole = role;
			  }
		  }
		  
		  if(sessionStorage.site_id && !sessionStorage.CurrentSite){
			  if(window.HPAYCompanies[sessionStorage.company_id]){
				  if(window.HPAYCompanies[sessionStorage.company_id].HPAYSites){
					  if(window.HPAYCompanies[sessionStorage.company_id].HPAYSites[sessionStorage.site_id]){
						  let site = window.HPAYCompanies[sessionStorage.company_id].HPAYSites[sessionStorage.site_id];
						  sessionStorage.CurrentSite = JSON.stringify({ id: site.id, site:site.Url });
						  sessionStorage.site_id = site.id;
						  localStorage.LastSite = sessionStorage.CurrentSite;
					  }
				  }
			  }
		  }
	  }
	  
	  if(sessionStorage.CurrentCompany && sessionStorage.CurrentSite && sessionStorage.gotolink){
		let link = sessionStorage.gotolink;
		delete sessionStorage.gotolink;
		history.push(link);
	  }
  }
  
  const object_if_json_string = (str) => {
	if(!str){
		return {};
	}
	
	if (!(typeof str === 'string' || str instanceof String)){
	  return str;
    }
	
	try{
		return JSON.parse(str);
	}catch(ex){
		return {};
	}
  };
  
  const prepareObjectForUse = (obj, full) => {
	
	if(obj && obj.toJSON){
		obj = obj.toJSON();
	}
	
	Object.keys(obj).filter(k => /^Data$|[a-z]Data$/.test(k) && obj[k]).forEach(k=> {
		obj[k] = object_if_json_string(obj[k]);
	});
	
	if(full){
		Object.keys(obj).filter(k => /^Subscription$|^Order$/.test(k) && obj[k]).forEach(k=> {
			obj[k] = prepareObjectForUse(obj[k], full);
		});
		
		Object.keys(obj).filter(k => /^Orders$|^Transactions$/.test(k) && obj[k]).forEach(k=> {
			if(obj[k] && obj[k].length){
				obj[k] = obj[k].map(item => prepareObjectForUse(item, full));
			}
		});
	}
	
	return obj;
  };
  
  const doUserSpaceInit = () => {
	  try{  
	    if(sessionStorage.AccessToken){
			
			loadUserEntities(null, true);
			
			let tdata = JSON.parse(sessionStorage.AccessToken || "{}");
			SOCKET_CLIENT.socket = io(configData.API_URL.split("/api")[0] ,{path: configData.IO_ENDPOINT, query:"useruid=" + tdata.UserId + "&token=" + tdata.Token + "&onconnectcompanyid=" + (sessionStorage.company_id || "") + "&onconnectsiteid=" + (sessionStorage.site_id || "")});
			window.getIOClient = () => {
				if(SOCKET_CLIENT.socket){
					if(!SOCKET_CLIENT.socket.connected){
						SOCKET_CLIENT.socket.connect();
					}
				}
				return SOCKET_CLIENT.socket;
			};
			
			if(!window._hpay_evt_cache){
				window._hpay_evt_cache = {};
			}
			
			SOCKET_CLIENT.socket.on("connect", () => {
				if(!SOCKET_CLIENT.socket.__hooks_placed){
					SOCKET_CLIENT.socket.__hooks_placed = true;
					
					SOCKET_CLIENT.socket.on("onOrderCreated", function(data){
						
						  let event = new Event("hpay-order-created");
						  
						  data.order = prepareObjectForUse(data.order, true);
						  
						  event.Order     = data.order;
						  event.CompanyId = data.company_id;
						  event.SiteId    = data.site_id;
						  event.HPayEventName = "hpay-order-created";
						  
						  try{	
						    if(!window._hpay_evt_cache[event.SiteId]) window._hpay_evt_cache[event.SiteId] = {subscriptions:{},orders:{},transactions: {}};
						  
							window._hpay_evt_cache[event.SiteId].orders[data.order.id] = data.order;
							if(event.Order.SubscriptionId && !event.Order.Subscription){
								if(window._hpay_evt_cache[event.SiteId].subscriptions[event.Order.SubscriptionId]){
									event.Order.Subscription = window._hpay_evt_cache[event.SiteId].subscriptions[event.Order.SubscriptionId];
								}
							}
						  }catch(ex){
							  
						  }
						  
						  window.dispatchEvent(event);
						  
						  let company_n = "";
						  if(window.HPAYCompanies && data.company_id && window.HPAYCompanies[data.company_id]){
							  company_n = (window.HPAYCompanies[data.company_id].Name || ""); 
						  }
				
						  setAlertMessage(t("New order") + ", " + company_n + ": " + data.order.Uid + ", amount: " + data.order.Amount + " " + data.order.Currency);
						  setAlertType("info");
						  setAlertOpen(true);
						  beep();
					});
					
					SOCKET_CLIENT.socket.on("onSubscriptionCreated", function(data){
						  let event = new Event("hpay-subscription-created");
						  
						  data.subscription = prepareObjectForUse(data.subscription, true);
						  
						  event.Subscription = data.subscription;
						  event.CompanyId = data.company_id;
						  event.SiteId    = data.site_id;
						  event.HPayEventName = "hpay-subscription-created";
						  
						  try{	
						  
						    if(!window._hpay_evt_cache[event.SiteId]) window._hpay_evt_cache[event.SiteId] = {subscriptions:{},orders:{},transactions: {}};
							
							window._hpay_evt_cache[event.SiteId].subscriptions[data.subscription.id] = data.subscription;
						  }catch(ex){
							  
						  }
						  
						  window.dispatchEvent(event);
						  
						  // setAlertMessage(t("New subscription") + ": " + data.subscription.Uid + ", amount: " + data.subscription.Amount + " " + data.subscription.Currency);
						  // setAlertType("info");
						  // setAlertOpen(true);
					});
					
					SOCKET_CLIENT.socket.on("onTransactionCreated", function(data){
						  let event = new Event("hpay-transaction-created");
						  
						  data.transaction = prepareObjectForUse(data.transaction, true);
						  
						  
						  event.Transaction = data.transaction;
						  event.CompanyId = data.company_id;
						  event.SiteId    = data.site_id;
						  event.HPayEventName = "hpay-transaction-created";
						  
						  try{	
							if(!window._hpay_evt_cache[event.SiteId]) window._hpay_evt_cache[event.SiteId] = {subscriptions:{},orders:{},transactions: {}};
						  
							window._hpay_evt_cache[event.SiteId].transactions[data.transaction.id] = data.transaction;
							if(event.Transaction.OrderId && !event.Transaction.Order){
								if(window._hpay_evt_cache[event.SiteId].orders[event.Transaction.OrderId]){
									event.Transaction.Order = window._hpay_evt_cache[event.SiteId].orders[event.Transaction.OrderId];
								}
							}
						  }catch(ex){
							  
						  }
						  
						  window.dispatchEvent(event);
					});
					
					SOCKET_CLIENT.socket.on("onOrderUpdated", function(data){
						  let event = new Event("hpay-order-updated");
						  data.order = prepareObjectForUse(data.order, true);
						  
						  event.Order = data.order;
						  event.CompanyId = data.company_id;
						  event.SiteId    = data.site_id;
						  event.HPayEventName = "hpay-order-updated";
						  
						  try{	
						    if(!window._hpay_evt_cache[event.SiteId]) window._hpay_evt_cache[event.SiteId] = {subscriptions:{},orders:{},transactions: {}};
						  
							window._hpay_evt_cache[event.SiteId].orders[data.order.id] = data.order;
							if(event.Order.SubscriptionId && !event.Order.Subscription){
								if(window._hpay_evt_cache[event.SiteId].subscriptions[event.Order.SubscriptionId]){
									event.Order.Subscription = window._hpay_evt_cache[event.SiteId].subscriptions[event.Order.SubscriptionId];
								}
							}
						  }catch(ex){
							  
						  }
						  
						  window.dispatchEvent(event);
					});
					
					SOCKET_CLIENT.socket.on("onSubscriptionUpdated", function(data){
						  let event = new Event("hpay-subscription-updated");
						  
						  data.subscription = prepareObjectForUse(data.subscription, true);
						  
						  event.Subscription = data.subscription;
						  event.CompanyId = data.company_id;
						  event.SiteId    = data.site_id;
						  event.HPayEventName = "hpay-subscription-updated";
						  
						  try{	
							if(!window._hpay_evt_cache[event.SiteId]) window._hpay_evt_cache[event.SiteId] = {subscriptions:{},orders:{},transactions: {}};
						  
							window._hpay_evt_cache[event.SiteId].subscriptions[data.subscription.id] = data.subscription;
						  }catch(ex){
							  
						  }
						  
						  
						  window.dispatchEvent(event);
					});
					
					SOCKET_CLIENT.socket.on("onTransactionUpdated", function(data){
						  let event = new Event("hpay-transaction-updated");
						  
						  data.transaction = prepareObjectForUse(data.transaction, true);
						  
						  event.Transaction = data.transaction;
						  event.CompanyId = data.company_id;
						  event.SiteId    = data.site_id;
						  event.HPayEventName = "hpay-transaction-updated";
						  
						  try{	
							if(!window._hpay_evt_cache[event.SiteId]) window._hpay_evt_cache[event.SiteId] = {subscriptions:{},orders:{},transactions: {}};
						  
							window._hpay_evt_cache[event.SiteId].transactions[data.transaction.id] = data.transaction;
							if(event.Transaction.OrderId && !event.Transaction.Order){
								if(window._hpay_evt_cache[event.SiteId].orders[event.Transaction.OrderId]){
									event.Transaction.Order = window._hpay_evt_cache[event.SiteId].orders[event.Transaction.OrderId];
								}
							}
						  }catch(ex){
							  
						  }
						  
						  window.dispatchEvent(event);
					});
				}
	 		});
			
			if(sessionStorage.gotolink){
				checkGoToLink();
		    }
			
			function sha512Async(str, callback) {
			  return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(buf => {
				let res = Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
				if(callback) callback(res);
				return res;
			  });
			}
			
			window.hpay_md5         = md5;
			window.hpay_sha512Async = sha512Async;
			
			let generatePOSRequestSignature = async (request, company_id, site_id) => {
				try{
					
					if(!company_id)
						company_id = sessionStorage.company_id;
					
					if(!site_id)
						site_id = sessionStorage.site_id;
					
					if(!company_id || !site_id || !window.HPAYCompanies){
						return null;
					}else if(!window.HPAYCompanies[company_id]){
						return null;
					}else if(!window.HPAYCompanies[company_id].HPAYSites){
						return null;
					}else if(!window.HPAYCompanies[company_id].HPAYSites[site_id])
						return null;
					
					let merchantUid = window.HPAYCompanies[company_id].HPAYSites[site_id].MerchantsiteUid;
					let secretkey   = window.HPAYCompanies[company_id].HPAYSites[site_id].SecretToken.Token;
					
					let amt_for_signature = parseFloat(request.order_amount || 0).toFixed(8);
					let cstr = String(request.transaction_uid || "").trim() + "|";
					cstr += String(request.status || "").trim() + "|";
					cstr += String(request.order_uid || "").trim()    + "|";
					cstr += String(amt_for_signature || "").trim()   + "|";
					cstr += String(request.order_currency || "").trim()     + "|";
					cstr += String(request.vault_token_uid  || "").trim() + "|";
					cstr += String(request.subscription_uid || "").trim();
					cstr += String(request.rand || "").trim();
					
					let cstrmd5 = md5(cstr + merchantUid);
					return await sha512Async(cstrmd5 + secretkey);
				}catch(ex){
					return null;
				}
			};
			window.HPayGeneratePOSRequestSignature =  generatePOSRequestSignature;
			
			
			let verifyPOSResultSignature = async (result, company_id, site_id) => {
				try{
					
					if(!company_id)
						company_id = sessionStorage.company_id;
					
					if(!site_id)
						site_id = sessionStorage.site_id;
					
					if(!company_id || !site_id || !window.HPAYCompanies){
						return null;
					}else if(!window.HPAYCompanies[company_id]){
						return null;
					}else if(!window.HPAYCompanies[company_id].HPAYSites){
						return null;
					}else if(!window.HPAYCompanies[company_id].HPAYSites[site_id])
						return null;
					
					let merchantUid = window.HPAYCompanies[company_id].HPAYSites[site_id].MerchantsiteUid;
					let secretkey   = window.HPAYCompanies[company_id].HPAYSites[site_id].SecretToken.Token;
					
					let amt_for_signature = parseFloat(result.order_amount || 0).toFixed(8);
					
					let cstr = String(result.transaction_uid || "").trim() + "|";
					cstr += String(result.status || "").trim() + "|";
					cstr += String(result.order_uid || "").trim()    + "|";
					cstr += String(amt_for_signature || "").trim()   + "|";
					cstr += String(result.order_currency || "").trim()     + "|";
					cstr += String(result.vault_token_uid  || "").trim() + "|";
					cstr += String(result.subscription_uid || "").trim();
					cstr += String(result.rand || "").trim();
					
					let cstrmd5 = md5(cstr + merchantUid);
					let expected_hash = await sha512Async(cstrmd5 + secretkey);
					
					let res = {};
				
					if(!result.verificationhash){
						res.result = "failed";
						return res;
					}
					
					if(expected_hash.toLowerCase() != result.verificationhash.toLowerCase()){
						let err = {
							expected_signature: expected_hash,
							fount_signature: result.verificationhash || "",
							cstr: cstr,
							cstrmd5: cstrmd5
						};
						
						if(/^\|\|/.test(cstr)){
							//COMAPATIBILITY VERIFICATION
							cstr = cstr.substring(2);
							cstrmd5 = md5(cstr + merchantUid);
							expected_hash = await sha512Async(cstrmd5 + secretkey);
							if(expected_hash.toLowerCase() == result.verificationhash.toLowerCase()){
								res.result = "ok";
							}
						}
						
						if(res.result != "ok"){
							res.result = "failed";
							console.error(err);
						}
					}else{
						res.result = "ok";
					}
					
					return res;
				}catch(ex){
					return null;
				}
			};
			window.HPayVerifyPOSResultSignature = verifyPOSResultSignature;
		}
	}catch(ex){
		
	}
  };
  

  useEffect(() => {
	
	window.addEventListener("hpay-company-changed", onCompanyChange, false);
	window.addEventListener("hpay-entities-invalidate", loadUserEntitiesForce, false);
	window.addEventListener("hpay-company-sites-invalidate", forceLoadSitesForCurrentCompany, false);
	window.addEventListener("click", interactionCounter, false);
	
	if(sessionStorage.AccessToken){
		fetch(configData.API_URL + "me", {
		  method: 'GET',
		  headers:{
			  Authorization: JSON.parse(sessionStorage.AccessToken || "{}").Token
		  }
		}).then(rawresp => {
			return rawresp.json();
		}).then(resp => {
			try{
				if(!resp.id || !resp.Email){
					sessionStorage.afterloginurl = location.pathname;
					
					delete sessionStorage.AccessToken;
					removeCookie("hpayremember");
					removeCookie("hpayrememberexp");
					
					history.push('/login', { replace: true });
				}else if(sessionStorage.UserData.indexOf(resp.Email) === -1){
					
					sessionStorage.afterloginurl = location.pathname;
					
					delete sessionStorage.AccessToken;
					removeCookie("hpayremember");
					removeCookie("hpayrememberexp");
					
					history.push('/login', { replace: true });
				}else{
					doUserSpaceInit(true);
				}
			}catch(ex){
				sessionStorage.afterloginurl = location.pathname;
				
				delete sessionStorage.AccessToken;
				removeCookie("hpayremember");
				removeCookie("hpayrememberexp");
					
				history.push('/login', { replace: true });
			}
		}).catch(err => {
			sessionStorage.afterloginurl = location.pathname;
			
			delete sessionStorage.AccessToken;
			removeCookie("hpayremember");
			removeCookie("hpayrememberexp");
				
			history.push('/login', { replace: true });
		});
		
		if(window._hpay_refresh_session_interval){
			clearInterval(window._hpay_refresh_session_interval);
			window._hpay_refresh_session_interval = null;
		}
		
		if(cookies.hpayrememberexp)
			window._hpay_refresh_session_interval = setInterval(extendSession,20000);
	}
	
	return () => {
		window.removeEventListener("hpay-company-changed", onCompanyChange, false);
		window.removeEventListener("hpay-entities-invalidate", loadUserEntitiesForce, false);
		window.removeEventListener("hpay-company-sites-invalidate", forceLoadSitesForCurrentCompany, false);
		window.removeEventListener("click", interactionCounter, false);
		
		if(window._hpay_refresh_session_interval){
			clearInterval(window._hpay_refresh_session_interval);
			window._hpay_refresh_session_interval = null;
		}
		
	};
	
  }, []);
  
  
  
  return (
    <RootStyle className={((sessionStorage.addsite || sessionStorage.windowedby || !sessionStorage.AccessToken || sessionStorage.gotolink ) ? "hpay-in-connect": "")}>
	  <SessionControl />
	  <Snackbar open={AlertOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={AlertType} sx={{ width: '100%' }}>
          {AlertMessage}
        </Alert>
      </Snackbar>
      {(!sessionStorage.windowedby && sessionStorage.AccessToken && !sessionStorage.gotolink) ? <DashboardNavbar onOpenSidebar={() => setOpen(true)} />: null}
      {(!sessionStorage.windowedby && sessionStorage.AccessToken && !sessionStorage.gotolink) ? <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />: null}
      <MainStyle>
	    {(initDone && sessionStorage.AccessToken && !sessionStorage.gotolink) ? children : <div>
			<div style={{"height": "90vh"}} className={"center-child"}><div className={"lds-roller"}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
		</div>}
      </MainStyle>
	  <GlobalElements/>	
    </RootStyle>
  );
}
