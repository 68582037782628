import { filter } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Card, Button, Table, Stack, TableRow, TableHead, TableBody, TableCell, Container, Typography, TableContainer, TablePagination } from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../components/_dashboard/user';
import configData from "../config.json";
import { fetchUtils } from 'react-admin';
import jsonServerProvider from '../data_provider';
import { useHistory } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Icon } from '@iconify/react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//import SelectSite from './SelectSite'
import { useTranslation } from 'react-i18next';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/*
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
*/

export default function FiscalMethods() {
  //const [page, setPage] = useState(0);
  //const [order, setOrder] = useState('asc');
  //const [selected, setSelected] = useState([]);
 // const [orderBy, setOrderBy] = useState('name');
  //const [filterName, setFilterName] = useState('');
  // eslint-disable-next-line
  const [siteFiscalMethods, setSiteFiscalMethods] = useState([]);
  const [siteFiscalMethodsMap, setSiteFiscalMethodsMap] = useState({});
  
  //const [rowsPerPage, setRowsPerPage] = useState(50);
  // eslint-disable-next-line
  const history = useHistory();
  const [AlertMessage, setAlertMessage] = useState("success");
  const [AlertType, setAlertType] = useState("success");
  const [AlertOpen, setAlertOpen] = useState(false);
  const { t } = useTranslation();
  
  const [loading, setLoading] = useState(true);

  // const TABLE_HEAD = [
    // { id: 'method', label: t('Fiscal/integration method'), alignRight: false },
	// { id: 'name', label: t('Name'), alignRight: false },
    // { id: 'enabled', label: t('Enabled'), alignRight: false },
	// { id: 'order', label: t('Order'), alignRight: false },
    // { id: 'action', label: "", alignRight: false },
  // ];

  const AlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const httpClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json', xcompanyid: sessionStorage.company_id, xsiteid: sessionStorage.site_id  });
    }
    const token = JSON.parse(sessionStorage.AccessToken || "{}").Token;
    options.headers.set('Authorization', `${token}`);
    return fetchUtils.fetchJson(url, options);
  };
  const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);

  // const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === 'asc';
    // setOrder(isAsc ? 'desc' : 'asc');
    // setOrderBy(property);
  // };

  // const handleSelectAllClick = (event) => {
    // if (event.target.checked) {
      // const newSelecteds = siteFiscalMethods.map((n) => n.siteurl);
      // setSelected(newSelecteds);
      // return;
    // }
    // setSelected([]);
  // };

  // const handleChangePage = (event, newPage) => {
    // setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  // };

  // const handleFilterByName = (event) => {
    // setFilterName(event.target.value);
  // };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - siteFiscalMethods.length) : 0;
  // const isSiteFiscalMethodNotFound = siteFiscalMethods.length === 0;
  
  const siteFiscalMethodEdit = (Id) => {
    const url = `/fiscaldetails/${Id}`;
    history.push(url);
  }

  const createfiscal = (params) => {
    history.push('newfiscal')
  }


  const fiscalMethodsDelete = (id, name) => {
	  
	  
	  confirmAlert({
		  title: t('Remove fiscal/integration method') + "?",
		  message: t('Please confirm you want remove fiscal/integration method') + " '" + name + "'." + t('You may lose important data by deleting this method.') ,
		  buttons: [
			{
			  label: t('Remove fiscal/integration method'),
			  onClick: () => {
				  dataProvider.delete('company_site_fiscal_methods', { id: id })
				  .then(res => {
					setAlertMessage(t("Fiscal/integration method is deleted"));
					setAlertType("success");
					setAlertOpen(true);
					loadData();
				  })
				  .catch(error => {
					setAlertMessage(t("Error: fiscal/integration method could not be deleted!"));
					setAlertType("error");
					setAlertOpen(true);
				  })
			  }
			},
			{
			  label: t('Cancel'),
			  onClick: () => {}
			}
		  ]
		});
  }

  const loadData = (params) => {
    if (sessionStorage.company_id && sessionStorage.site_id) {
		
	  dataProvider.getList('company_site_fiscal_methods', { pagination: { page: 1, perPage: 99999 }, sort: { field: 'Order', order: 'ASC' }, filter: {
		  CompanySiteId: sessionStorage.site_id
	  } })
		.then(res => {
		  let data_map = {};
		  for(let i = 0; i < res.data.length;i++){
			  try{
				res.data[i].Data = JSON.parse(res.data[i].Data);
			  }catch(ex){
				res.data[i].Data = { parameters: {} };
			  }
			  
			  if(!res.data[i].Data){
				  res.data[i].Data = { parameters: {} };
			  }
			  
			  if(!res.data[i].Data.parameters){
				  res.data[i].Data.parameters = {};
			  }
			  
			  if(!res.data[i].FiscalMethod)
				  res.data[i].FiscalMethod = {Data: {parameters:[]}};
			  
			  if(!res.data[i].FiscalMethod.Data){
				  res.data[i].FiscalMethod.Data = {parameters:[]};
			  }
				
			  if(!res.data[i].FiscalMethod.Data.parameters){
				  res.data[i].FiscalMethod.Data.parameters = [];
			  }
			  
			  data_map[res.data[i].id] = res.data[i];
		  }
		  
		  
		  //res.data = applySortFilter(res.data, getComparator(order, orderBy), filterName)
		  setLoading(false);
		  
		  setSiteFiscalMethods(res.data);
		  setSiteFiscalMethodsMap(data_map);
		  
		})
		.catch(error => {
			if(error && error.body && error.body.error && /^401 /.test(error.body.error)){
				let event = new Event("hpay-auth-expired");
				window.dispatchEvent(event);
			}
				
		  setAlertMessage(t("Error loading site fiscal/integration methods!"));
		  setAlertType("error");
		  setAlertOpen(true);
	  
		  console.log(error)
		})	
    } else {
      setAlertMessage(t("Please select a POS!"));
      setAlertType("error");
      setAlertOpen(true);
    }
  }
  useEffect(() => {
    loadData();
	
	window.addEventListener("hpay-site-changed", loadData, false);
	return () => {
		window.removeEventListener("hpay-site-changed", loadData, false);
	};
	
	delete window.__sitesData;
	
    // eslint-disable-next-line
  }, [])

  return (
    <Page title="Fiscal/integration methods | Holest">

      <Snackbar open={AlertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={AlertClose}>
        <Alert onClose={AlertClose} severity={AlertType} sx={{ width: '100%' }}>
          {AlertMessage}
        </Alert>
      </Snackbar>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
         <Stack direction="column">
		  <Typography variant="h4" gutterBottom>
            {t("Fiscal/integration methods")}
          </Typography>
		  <Typography variant="p" gutterBottom>
            *** {JSON.parse(sessionStorage.CurrentSite || "{}").name} ***
          </Typography>
		   <Typography variant="p" gutterBottom style={{fontSize:"80%"}}>
            ** {t("fiscal/e-invoice tax system integration, embedded devices (like carwash coin machines) or external system notifications (production line intstructions)")}
          </Typography>
		  
		  </Stack>
          {/*
		  <SelectSite reload={loadData} />
		  */}
		  {sessionStorage.CurrentUserRole != "staff" ?
          <Button
            variant="contained"
            onClick={(e) => createfiscal()}
            startIcon={<Icon icon={plusFill} />}
            color="secondary"
          >
            {t("Add New Fiscal/Integration Method")}
          </Button> : null}
        </Stack>

        <Card>
		{/*	
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
		*/}  

          <Scrollbar>
		    
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
			  {/*
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={siteFiscalMethods.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
			  */}
				<TableHead>
					<TableRow>
						<TableCell>{t("Fiscal/integration method")}</TableCell> 
						<TableCell>{t("Name")}</TableCell> 
						<TableCell>{t("Enabled")}</TableCell> 
						<TableCell>{t("List order")}</TableCell> 
						<TableCell></TableCell> 
					
					</TableRow>
				</TableHead>
                <TableBody>
                  {siteFiscalMethods
                    .map((row, key) => {
                      // eslint-disable-next-line
                      //const { id, name, data, date } = row;
                      //const isItemSelected = selected.indexOf(row.id) !== -1;
                      return (
                        <TableRow
                          hover
                          key={key}
                          tabIndex={-1}
                          role="checkbox"
                          //selected={isItemSelected}
                          //aria-checked={isItemSelected}
						  className='clickable'
                        >
                          
						  <TableCell onClick={(e) => siteFiscalMethodEdit(row.id)} component="th" scope="row">
                            <Typography variant="subtitle2" noWrap>
                              {row.FiscalMethod.Name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" onClick={(e) => siteFiscalMethodEdit(row.id)}>{row.Data.parameters.Name}</TableCell>
						  <TableCell align="left" onClick={(e) => siteFiscalMethodEdit(row.id)}>{row.Enabled ? t("Yes") : t("No")}</TableCell>
						  <TableCell align="left" onClick={(e) => siteFiscalMethodEdit(row.id)}>{row.Order}</TableCell>
						  <TableCell align="right" >
						  
						  {sessionStorage.CurrentUserRole != "staff" ?
						  <DeleteForeverIcon onClick={(e) => fiscalMethodsDelete(row.id, row.FiscalMethod.Name)} /> : null}
						  
						  </TableCell>

                        </TableRow>
                      );
                    })}
                  {!siteFiscalMethods.length && (
                    <TableRow>
                      <TableCell colSpan={5} />
                    </TableRow>
                  )}
                </TableBody>
                {/*isSiteFiscalMethodNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )*/}
              </Table>
            </TableContainer>
		  </Scrollbar>
		  {/*
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={siteFiscalMethods.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
		  */}
        </Card>
      </Container>
	  {loading ? <div className={"center-child"}><div className={"lds-roller"}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>  : null}
    </Page>
  );
}
