import { filter } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { Link as RouterLink } from 'react-router-dom';
import { Card, Table, Stack, Select, MenuItem, Button, TableRow, TableBody, TableCell,TableContainer, TablePagination, Container, Typography  } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../components/_dashboard/user';
import TextField from '@mui/material/TextField';
import SiteMore from './sitesbuttons'
import configData from "../config.json";
import { fetchUtils } from 'react-admin';
import jsonServerProvider from '../data_provider';
//import SelectSite from './SelectSite'
import { useHistory } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import SelecetCompany from './SelecetCompany';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import 'react-confirm-alert/src/react-confirm-alert.css';

var md5 = require('md5');

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const isSandbox = configData.API_URL.indexOf("sandbox") > -1;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.siteurl.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Sites() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('siteurl');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [siteurl, setsiteurl] = useState("");
  const [siteurls, setsiteurls] = useState("");
  const [sites, setsites] = useState([]);
  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [AlertMessage, setAlertMessage] = useState("success");
  const [AlertType, setAlertType] = useState("success");
  const [AlertOpen, setAlertOpen] = useState(false);
  const [siteType, setSiteType] = useState('site');
  const [siteSubtype, setSiteSubtype] = useState('standard');
  
  const [inputValid, setInputValid] = useState({valid:null, sites_valid:null, message: ""});
  
  const [loading, setLoading] = useState(true);
  
  const [rNo, setrNo] = useState(1);
  
  
  const { t } = useTranslation();
  const TABLE_HEAD = [
    { id: 'siteurl', label: t('POS uri'), alignRight: false },
	{ id: 'type', label: t('Type'), alignRight: false },
	{ id: 'enabled', label: t('Enabled'), alignRight: false },
    { id: 'siteurls', label: t('Additional url-s'), alignRight: false },
    { id: 'posuid', label: t('Merchant POS uid'), alignRight: false },
	{ id: 'keyexp', label: t('HOLESTKey exp.'), alignRight: false },
    { id: '' }
  ];

  const AlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
  
  const httpClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json', xcompanyid: sessionStorage.company_id, xsiteid: sessionStorage.site_id  });
    }
    const token = JSON.parse(sessionStorage.AccessToken || "{}").Token;
    options.headers.set('Authorization', `${token}`);
    return fetchUtils.fetchJson(url, options);
  };
  
  const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);
  const handleClickAddSite = () => {
	  
	  
	  if(!window._HPAY_POS_TYPES){
		  dataProvider.getList("pos_types",{ pagination: { page: 1, perPage: 9999 }, sort: { field: 'Order', order: 'ASC' }}).then(r => {
			  
			  window._HPAY_POS_TYPES = r?.data || [];
			  for(var i in window._HPAY_POS_TYPES){
				  if(window._HPAY_POS_TYPES.hasOwnProperty(i) && window._HPAY_POS_TYPES[i].Data){
					  for(var prop in window._HPAY_POS_TYPES[i].Data){
						  if(window._HPAY_POS_TYPES[i].Data.hasOwnProperty(prop)){
							  if(prop == "parameters"){
								  for(var index in window._HPAY_POS_TYPES[i].Data.parameters){
									  if(window._HPAY_POS_TYPES[i].Data.parameters.hasOwnProperty(index)){
										  let p = window._HPAY_POS_TYPES[i].Data.parameters[index];
										  for(var pprop in p){
											  if(p.hasOwnProperty(pprop) && typeof p[pprop] === 'string' && /^function/.test(p[pprop])){
												  try{
													  eval('p[pprop] = (' + p[pprop] + ");");
												  }catch(fex){
													  console.error(fex);
												  }
											  }
										  }
										  window._HPAY_POS_TYPES[i].Data.parameters[index] = p;
									  }
								  }
							  }else if(typeof window._HPAY_POS_TYPES[i].Data[prop] === 'string'){
								  if(/^function/.test(window._HPAY_POS_TYPES[i].Data[prop])){
									  try{
										  eval('window._HPAY_POS_TYPES[i][prop] = (' + window._HPAY_POS_TYPES[i].Data[prop] + ");");
										  delete window._HPAY_POS_TYPES[i].Data[prop];
									  }catch(fex){
										  console.error(fex);
									  }
								  }
							  }
						  }
					  }
				  }
			  }
			  
		  }).catch(err => console.error).finally(() => {
			  if(!window._HPAY_POS_TYPES)
				  window._HPAY_POS_TYPES = [];
			  
			  setOpen(true);
		  });	
		  return;
	  }else
		  setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = sites.map((n) => n.siteurl);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  
  const handleSiteTypeChange = (arg) => {
	  setsiteurl("");
	  handleSiteSubtypeChange("");
	  setSiteType(arg.selectedValue);
  }; 
  
  const handleSiteSubtypeChange = (arg) => {
	  let subtype = arg.selectedValue || arg || "standard";
	  setSiteSubtype(subtype);
  }; 
  
  const handleSiteInputChange = (current_text) => {
	  if(siteType == "site"){
		  let surln = current_text.toLowerCase().replace(/ /g,"").replace("https://","").replace("http://","").replace("www.","").trim();
		  if(surln != current_text){
			  current_text = surln;
		  }
		  
		  if(current_text.trim()){
			  if(/^[^\.].*..\.[a-z]{2,20}(\/.*)?$/.test(current_text)){
				  if(!inputValid.valid){
					  inputValid.valid = true;
					  inputValid.message = "";
				  }
			  }else{
				  if(inputValid.valid){
					  inputValid.valid = false;
					  inputValid.message = t("enter valid url");
				  }
			  }
		  }else{
			  inputValid.valid = false;
			  inputValid.message = t("enter url");
		  }
		  
		  
		  
		  setsiteurl(current_text);
		  setInputValid(inputValid);
	  }else if(siteType == "app"){
		  let surln = current_text.toLowerCase().replace(/ /g,"").replace("https://","").replace("http://","").replace("www.","").trim();
		  if(surln != current_text){
			  current_text = surln;
		  }
		  inputValid.valid = current_text.length >= 6;
		  inputValid.message = "";
		  setInputValid(inputValid);
		  setsiteurl(current_text);
	  }else if(siteType == "splitpay"){
		  let surln = current_text.toLowerCase().replace(/ /g,"").replace("https://","").replace("http://","").replace("www.","").trim();
		  if(surln != current_text){
			  current_text = surln;
		  }
		  inputValid.valid = current_text.length >= 6;
		  inputValid.message = "";
		  setInputValid(inputValid);
		  setsiteurl(current_text);
	  } 
  };
  
  let urlsCorrectHandle = null;
  
  const handleSitesInputChange = (current_text) => {
	  
	  if(!current_text.trim()){
		  inputValid.sites_valid = null;
		  setInputValid(inputValid);
	  }else{
		  let has_bad = false;
		  let urls = [];
		  
		  let surlns = current_text.trim().replace(/ /g,"").toLowerCase().replace("https://","").replace("http://","").replace("www.","");
		  
		  surlns.split(",").forEach((current_text) => {
			  let valid = false; 
			  let surln = current_text.toLowerCase().replace("https://","").replace("http://","").replace("www.","").trim();
			  if(surln != current_text){
				  current_text = surln;
			  }
			  if(current_text){
				  if(/^[^\.].*..\.[a-z]{2,20}(\/.*)?$/.test(current_text)){
					  valid = true; 
					  urls.push(current_text);
				  }
			  }
			  if(!valid){
				  has_bad = true;
			  }
		  });
		  
		  if(has_bad){
			  if(inputValid.sites_valid !== false){
				  inputValid.sites_valid = false;
				  setInputValid(inputValid);
			  }
		  }else{
			  if(inputValid.sites_valid !== true){
				  inputValid.sites_valid = true;
				  setInputValid(inputValid);
				  if(current_text != urls.join(",")){
					  current_text = urls.join(",");
				  }
			  }
		  }
	  }
	  
	  setsiteurls(current_text);
  };
  
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sites.length) : 0;

  const filteredUsers = applySortFilter(sites, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const siteDelete = (Id, Url) => {
	  confirmAlert({
		  title: t('Delete site'),
		  message: t('Please confirm you want to delete site') + " '" + Url + "'." + t('You might lose important data releted to this site.') ,
		  buttons: [
			{
			  label: t('Delete'),
			  onClick: () => {
				 dataProvider.delete("company_sites", { id: Id })
				  .then(res => {
					  
					  
					  
					let event = new Event("hpay-company-sites-invalidate");
					window.dispatchEvent(event);  
					
					setIsOpen(false)
					setAlertMessage(t("Selected site is deleted!"));
					setAlertType("success");
					setAlertOpen(true);
					loadData();

				  }).catch(err => {
					  
						if(err && err.body && err.body.error && /^401 /.test(err.body.error)){
							let event = new Event("hpay-auth-expired");
							window.dispatchEvent(event);
						}
					  
					  
					    if(err.body.error && err.body.error.errors && err.body.error.errors[0] && err.body.error.errors[0].message){
							setAlertMessage(t("Error during site delete") + ": " + t(err.body.error.errors[0].message));	
						}else if(err.body.error){
							setAlertMessage(t("Error during POS delete") + ": " + t(err.body.error));
						}else{
							setAlertMessage(t("Error during site delete") + "!");	
						}
						setAlertType("error");
						setAlertOpen(true);
				  });
			  }
			},
			{
			  label: t('Cancel'),
			  onClick: () => {}
			}
		  ]
		});
  }

  const siteEdit = (Id) => {
    const url = `/sitedetails/${Id}`;
    history.push(url);
  }


  const save = async (params) =>  {
	
	if(inputValid.valid !== true || inputValid.sites_valid === false){
		setAlertMessage(t("Please correct your input!"));
		setAlertType("error");
		setAlertOpen(true);
	}else{	
		
		let siteurl_n = siteurl.trim().replace(/ /g,"").toLowerCase().replace("https://","").replace("http://","").replace("www.","").replace(/\/$/,"");
		let siteurls_n = "";
		if(siteurls){
			siteurls_n = ("," + siteurls + ",").replace("," + siteurl_n + ",","");
			
			siteurls_n = siteurls_n.substring(1);
			siteurls_n = siteurls_n.substring(siteurls_n,0,siteurls_n.length - 1);
			siteurls_n = siteurls_n.split(",").map((aurl) => {
				return aurl.trim().replace(/ /g,"").toLowerCase().replace("https://","").replace("http://","").replace("www.","").replace(/\/$/,"");
			}).join(",");
		}
		
		let create_data = { 
			Url:       siteurl_n, 
			Urls:      siteurls_n, 
			CompanyId: sessionStorage.company_id,
			SiteType:  siteType	+ ((!siteSubtype || siteSubtype == 'standard') ? "" : ("-" + siteSubtype))
		};
		
		let ptype = null;
		
		if(siteSubtype && siteSubtype !== 'standard'){
			ptype = window._HPAY_POS_TYPES?.find(s => s.Name == create_data.SiteType); 
			if(ptype && ptype.filterCreateData){
				try{
					let d = ptype.filterCreateData(create_data);
					if(d){
						if (typeof d?.then === 'function') {
							create_data = await d;	
						}else{
							create_data = d;
						}
					}
				}catch(cex){
					console.error(cex);
				}
			}
		}
		
		if(!create_data){
			return;
		}
	
		dataProvider.create("company_sites", { data: create_data})
		  .then(res => {
			if(res){
				if(res.data){
					if(res.data.id){
						sessionStorage.CurrentSite = JSON.stringify({ id: res.data.id, name: res.data.Url});
						sessionStorage.site_id = res.data.id;
						
						let event = new Event("hpay-company-sites-invalidate");
						window.dispatchEvent(event);  
						
						setOpen(false);
						setAlertMessage(t("The POS is added successfully!"));
						setAlertType("success");
						setAlertOpen(true);
						
						setTimeout(function(){
							siteEdit(res.data.id);
						},1200);
						
						return;
					}
				}	
			}
			
		  }).catch(err => {
					if(err && err.body && err.body.error && /^401 /.test(err.body.error)){
							let event = new Event("hpay-auth-expired");
							window.dispatchEvent(event);
						}
			  
					if(err.body.error && err.body.error.errors && err.body.error.errors[0] && err.body.error.errors[0].message){
						setAlertMessage(t("Error during POS add") + ": " + t(err.body.error.errors[0].message));	
					}else if(err.body.error){
						setAlertMessage(t("Error during POS add") + ": " + t(err.body.error));
					}else{
						setAlertMessage(t("Error during POS add") + "!");	
					}
					setAlertType("error");
					setAlertOpen(true);
			  });;
	}
  }

  const loadData = (params) => {
    if (sessionStorage.company_id) {
	   dataProvider.getList("company_sites", { pagination: { page: 1, perPage: 9999 }, sort: { field: 'url', order: 'ASC' }, filter: { CompanyId: sessionStorage.company_id } })
        .then(res => {
			
		  setLoading(false);	
			
          const data = res.data;
          const res_data = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].CompanyId == sessionStorage.company_id) {
              res_data.push({
                Id: data[i].id,
                siteurl: data[i].Url,
                siteurls: data[i].Urls,
                companyname: JSON.parse(sessionStorage.CurrentCompany || "{}").name,
                date: data[i].CreatedAt,
				merchantsite_uid: data[i].MerchantsiteUid,
				site_type: data[i].SiteType,
				enabled: data[i].Enabled,
				expiresat: isSandbox ? t("sandbox: key not needed") : (data[i].KeyValidTill ? String(data[i].KeyValidTill).substring(0,10) : "-")
              })
            }
          }
		  
          if (res_data.length === 0) {
            sessionStorage.CurrentSite = JSON.stringify({ id: "", name: t("--not selected--") });
			sessionStorage.site_id = "";
            setAlertMessage(t("Please add a POS - Site, App or SplitPay."));
            setAlertType("info");
            setAlertOpen(true);
          }
          else if (params === "default") {
            sessionStorage.CurrentSite = JSON.stringify({ id: res_data[0].Id, name: res_data[0].siteurl });
			sessionStorage.site_id = res_data[0].Id;
          }
          else if (res_data.length === 1) {
            sessionStorage.CurrentSite = JSON.stringify({ id: res_data[0].Id, name: res_data[0].siteurl });
			sessionStorage.site_id = res_data[0].Id;
          }
          setsites(res_data);
		  
		  if(window.__hp_add_site){
			  window.__hp_add_site = false;
			  handleClickAddSite();
		  }
		  
        }).catch(err => {
			
			if(err && err.body && err.body.error && /^401 /.test(err.body.error)){
							let event = new Event("hpay-auth-expired");
							window.dispatchEvent(event);
						}
						
			setAlertMessage(t("Error during data loading") + "!");	
			setAlertType("error");
			setAlertOpen(true);
					
		});
    }
    else {
      setAlertMessage(t("Please select a company"));
      setAlertType("info");
      setAlertOpen(true);
    }
  };
  
  const site_type_options = [
	  { value: '', label: t('') },
	  { value: 'site', label: t('Web site') },
	  { value: 'app', label: t('IOS/Android App') },
	  { value: 'splitpay', label: t('Stand alone split-pay receiver') }
	];
	
  
  const onCompanyChange = () => {
	  
	  loadData("load");
	  
  }; 
  
  
  useEffect(() => {
    loadData("load");
	
	window.addEventListener("hpay-company-changed", onCompanyChange, false);
	
	if(sessionStorage.addsite){
		handleSiteInputChange(sessionStorage.addsite);
	}
	
	return () => {
		
		window.removeEventListener("hpay-company-changed", onCompanyChange, false);
		
	};
    // eslint-disable-next-line
  }, [])

  return (
  
    <Page title="Sites | Holest" label={{rNo}}>
      <Snackbar open={AlertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={AlertClose}>
        <Alert onClose={AlertClose} severity={AlertType} sx={{ width: '100%' }}>
          {AlertMessage}
        </Alert>
      </Snackbar>
	  
	  {sessionStorage.CurrentCompany ?
      <Container>
	  
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
         <Stack direction="column">
		  <Typography variant="h4" gutterBottom>
            {t("POS: Sites or Apps or SplitPay receivers")}
          </Typography>
		  <Typography variant="p" style={{fontSize:"80%"}} >
			  {sessionStorage.addsite ?
			  
			  <Button
                        variant="dark"
                        onClick={(e) => {
							
							delete sessionStorage.CurrentCompany;
							delete sessionStorage.company_id;
							history.push('/sites');
							
						}}
                        startIcon={<ExitToAppIcon />}
                        color="secondary"
                    >
                        {t("Back to companies list...")}
			  </Button> : null}
		  
		  
			*** {t("COMPANY")}: {JSON.parse(sessionStorage.CurrentCompany || "{}").name} ***
		  </Typography>
		 </Stack> 
          {/* <SelectSite reload={loadData} />*/}
          {sessionStorage.CurrentUserRole != "staff" ?
		  <Button
            // variant="contained"
            variant="contained"
            onClick={handleClickAddSite}
            component={RouterLink}
            to="#"
            startIcon={<AddTaskIcon />}
            color="secondary"
          >
            {t("Add a new POS")}
          </Button>: null}

          <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
            <DialogTitle>{t("Add a new POS")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("Add a POS for") + ": " + JSON.parse(sessionStorage.CurrentCompany || "{}").name}
              </DialogContentText>
			  
			  <br/>
			  {t("POS type")}: <Select
					labelId="post_type_label"
					id="post_type"
					value={siteType}
					label={t("POS type")}
					onChange={(e) => handleSiteTypeChange({selectedValue: e.target.value })}
				>
					{
						site_type_options.map((sub_item, sub_key) => {
							return (
								<MenuItem disabled={sessionStorage.addsite && sub_item.value != 'site'} key={sub_item.value} value={sub_item.value}>{sub_item.label}</MenuItem>
							)
						})
					}

				</Select>
			    
				<Select
					labelId="post_subtype_label"
					id="post_subtype"
					value={siteSubtype}
					label="/"
					onChange={(e) => handleSiteSubtypeChange({selectedValue: e.target.value })}
				>
				{
					((window._HPAY_POS_TYPES || []).filter(s => s.Name.indexOf(siteType) > -1)).map((sub_item, sub_key) => {
						return (
							<MenuItem key={sub_item.Name} value={sub_item.Name.replace(/^[^\-]*-/,'')}>{sub_item.Title}</MenuItem>
						)
					})
					
				}
				</Select>
			{ 
			((siteSubtype == "standard" || !siteSubtype) && siteType == "site") ?
			  <><p style={{fontSize:"70%"}}>{t("Standard type is for platforms where you install plugin/module like wooCommerce, OpenCart, Magento, etc..")}</p><br/></>
			  : null
			  }
				
			  
              <TextField
                autoFocus
                margin="dense"
                label={
					(siteType == "site" ? t("Main site url") : "")
					+ 
					(siteType == "app" ? t("IOS/Android bundle id-s - comma separated") : "")
					+ 
					(siteType == "splitpay" ? t("Idenificator like 'install-air-conditioner' or 'donation-j.doe' or 'joes-currier'") : "")
					}
                type="text"
                fullWidth
                variant="standard"
				error={inputValid.valid === false}
				helperText={inputValid.message}
                value={siteurl}
				onChange={(e) => handleSiteInputChange(e.target.value)}
              />
			  { 
			  siteType == "site" ?
			  <TextField
                margin="dense"
                label={t("Auxiliary site url-s") + " (" + t("comma separated, without www. and https://") + ")"}
                type="text"
                fullWidth
				error={inputValid.sites_valid === false}
                variant="standard"
                value={siteurls}
				onChange={(e) => handleSitesInputChange(e.target.value)}
              />
			  : null
			  }
			  
			  { 
			  siteType == "splitpay" ?
              <p className='note'>{t("NOTE: for registered sites and apps you can use site UID as split-pay identifier. You need a stand alone split-pay POS only when you don't have your site/app and you need to receive payments only through purhases over your partner's site(s).")}</p>
			  : null
			  }
			  
            </DialogContent>
            <DialogActions>
              <Button disabled={inputValid.valid !== true || inputValid.sites_valid === false} onClick={(e) => save()}>{t("Save")}</Button>
              <Button onClick={handleClose}>{t("Cancel")}</Button>
            </DialogActions>
			
			
			
          </Dialog>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
		  
		  
		  <Scrollbar>
			
		  
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={sites.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, key) => {
                      const { Id, siteurl, siteurls, companyname, date, merchantsite_uid, site_type, enabled, expiresat } = row;
                      const isItemSelected = sessionStorage.site_id == Id;

                      return (
                        <TableRow
                          hover
                          key={key}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
						  className='clickable'

                        >
                          <TableCell onClick={(e) => siteEdit(Id)} component="th" scope="row">
                            <Typography variant="subtitle2" noWrap>
                              {siteurl}
                            </Typography>
                          </TableCell>
						  
						  <TableCell onClick={(e) => siteEdit(Id)} align="left">{site_type}</TableCell>
						   <TableCell onClick={(e) => siteEdit(Id)} align="left">{enabled ? t("Yes") : t("No")}</TableCell>
                          <TableCell onClick={(e) => siteEdit(Id)} align="left">{siteurls}</TableCell>
						  {/*<TableCell align="left" onClick={(e) => siteEdit(Id)}> {companyname}</TableCell>*/}
                          <TableCell style={{'fontSize':'70%'}} align="left" onClick={(e) => e.preventDefault()}>{merchantsite_uid}</TableCell>
                          <TableCell style={{'fontSize':'70%'}} align="left" onClick={(e) => siteEdit(Id)}>{expiresat}</TableCell>
						  <TableCell align="right">
						  {sessionStorage.CurrentUserRole != "staff" ?
							<SiteMore Id={Id} Url={siteurl} siteDelete={siteDelete} siteEdit={siteEdit} /> : null}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
		  </Scrollbar>
		  {/*
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={sites.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
		  */}
        </Card>
		{loading ? <div className={"center-child"}><div className={"lds-roller"}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>  : null}
      </Container> : <div>
						<h2 style={{margin:"5px 8px"}}>{t("Please select a company")}...</h2>
						<SelecetCompany openCompany={true} handleCloseSelector={()=>{
							setTimeout(() => {
								if(sessionStorage.CurrentCompany){
									setrNo(rNo + 1);
								}
							},300);
						}} loadSites={loadData} />
					 </div>}
	  
	  
    </Page>
  );
}
