import Page from '../components/Page';
import { Container, Stack, Button, Typography, CardActionArea, TextField, FormControl, Input } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import CheckIcon from '@mui/icons-material/Check';
import configData from "../config.json";
import { fetchUtils } from 'react-admin';
import jsonServerProvider from '../data_provider';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, forwardRef } from 'react';

import ImageIcon from '@mui/icons-material/Image';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CreateCompany() {
	const [AlertMessage, setAlertMessage] = useState("success");
    const [AlertType, setAlertType]       = useState("success");
    const [AlertOpen, setAlertOpen]       = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	
	
	const AlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
    };
	
	const history = useHistory();
    const { t } = useTranslation();
    const [files, setFiles] = useState("");
    const RegisterSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, t('Too Short!'))
            .max(200, t('Too Long!'))
            .required(t('Name is required')),
        address: Yup.string()
            .min(2, t('Too Short!'))
            .max(200, t('Too Long!'))
            .required(t('Address is required')),
        regnumber: Yup.string().min(2, t('Too Short!')).max(15, t('Too Long!')).required(t('Reg Number required')),
        taxnumber: Yup.string().min(2, t('Too Short!')).max(15, t('Too Long!')).required(t('Tax Number required')),
        country: Yup.string().min(2, t('Too Short!')).max(15, t('Too Long!')).required(t('Country required')),
    });

    const httpClient = (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json', xcompanyid: sessionStorage.company_id, xsiteid: sessionStorage.site_id  });
        }
        const token = JSON.parse(sessionStorage.AccessToken || "{}").Token;
        options.headers.set('Authorization', `${token}`);
        return fetchUtils.fetchJson(url, options);
    };
	
	const setLogoPreview = (file) => {
		
		if(!file){
			if(document.querySelector(".upload-preview"))
				document.querySelector(".upload-preview").style["backgroundImage"] = "";
		}else{
			const reader = new FileReader();
			reader.addEventListener(
				"load",
				() => {
					
				 if(document.querySelector(".upload-preview"))
					document.querySelector(".upload-preview").style["backgroundImage"] = "url(" + reader.result + ")";
				},
				false
			);

			if (file) {
				reader.readAsDataURL(file);
			}
		}
	}

    const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);
    const MainUserId = JSON.parse(sessionStorage.AccessToken || "{}").UserId
    
	const commitCreate = (data) => {
		
		dataProvider.create('companies', data)
		.then(res => {
			setIsSubmitting(false);
			
			sessionStorage.CurrentCompany = JSON.stringify({ id: res.data.id, name: res.data.Name});
			sessionStorage.company_id = res.data.id;
			sessionStorage.CurrentUserRole = "creator";
			
			localStorage.lastCompany = sessionStorage.CurrentCompany;
			localStorage["hpay_last_company_id"] = sessionStorage.company_id;
			
			if(window.HPAYCompanies){
				window.HPAYCompanies[res.data.id] = res.data;
			}
			
			setAlertMessage(t("The company created successfully!"));
			setAlertType("success");
			setAlertOpen(true);
			
			let event = new Event("hpay-company-changed");
			window.dispatchEvent(event);
			
			setTimeout(function(){
					if(sessionStorage.addsite){
						history.push('/sites');
					}else{
						companyEdit(res.data.id);
					}
			},1500);
		})
		.catch(err => {
			setIsSubmitting(false);
			
			if(err && err.body && err.body.error && /^401 /.test(err.body.error)){
				let event = new Event("hpay-auth-expired");
				window.dispatchEvent(event);
			}
			
			if(err.body && err.body.error && err.body.error.errors && err.body.error.errors[0] && err.body.error.errors[0].message){
				setAlertMessage(t("Error during company create") + ": " + t(err.body.error.errors[0].message));	
			}else if(err.body && err.body.error){
				setAlertMessage(t("Error during company create") + ": " + t(err.body.error));
			}else{
				setAlertMessage(t("Error during company create") + "!");	
			}
			setAlertType("error");
			setAlertOpen(true);
			
		});
	};
	
	const formik = useFormik({
        initialValues: {
            name: '',
            file: '',
            taxnumber: '',
            address: '',
            regnumber: '',
            country: '',
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values, e) => {
            if (files === "") {
				
				setIsSubmitting(true);
				commitCreate({ data: { Name: values.name.trim(), LogoFile: "", Address: values.address, Country: values.country, TaxNo: values.taxnumber, RegNo: values.regnumber, MainUserId: MainUserId } });
            } else {
				
				setIsSubmitting(true);
				
                const data = new FormData();
                data.append('file', files);
				
				
				fetch(configData.API_URL + "upload", {
				  method: 'POST',
				  headers:{
					  Authorization: JSON.parse(sessionStorage.AccessToken || "{}").Token
				  },
				  body: data
				}).then(rawresp => {
					return rawresp.json();
				}).then(resp => {
					
					if(resp.error){
						setAlertMessage(t("Upload blocked!") + " " + files.name);
						setAlertType("error");
						setAlertOpen(true);
					}
					
					commitCreate({ data: { Name: values.name.trim(), LogoFile: resp.filename, Address: values.address, Country: values.country, TaxNo: values.taxnumber, RegNo: values.regnumber, MainUserId: MainUserId } });
					
				}).catch(err => {
					setIsSubmitting(false);
					 
					setAlertMessage(t("Upload blocked!") + " " + files.name);
					setAlertType("error");
					setAlertOpen(true);
				});
			}

        }
    });
	
	const back = (params) => {
		if(sessionStorage.addsite){
			history.push('/sites');
		}else{
			history.push('/company');
		}
    };
	
	const companyEdit = (id) => {
        history.push('/companydetails/' + id);
    };

    const { errors, touched, handleSubmit, getFieldProps } = formik;
		useEffect(() => {
		
		
	  }, []);
	return (
        <Page title="Company | Holest">
			<Snackbar open={AlertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={AlertClose}>
                <Alert onClose={AlertClose} severity={AlertType} sx={{ width: '100%' }}>
                    {AlertMessage}
                </Alert>
            </Snackbar>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {t("Company data")}
                    </Typography>
					<Button
                        variant="dark"
                        onClick={(e) => back()}
                        startIcon={<ExitToAppIcon />}
                        color="secondary"
                    >
                        {t("Back to companies list...")}
                    </Button>
                </Stack>
            </Container>
            <Grid container justifyContent="center">
                <Grid item xs={8}>
                    <Card>
                        <CardActionArea>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    {t("Company")}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardContent>
                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <Stack spacing={3}>
                                        <TextField
                                            fullWidth
                                            label={t("Company Name")}
                                            {...getFieldProps('name')}
                                            error={Boolean(touched.name && errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                       
                                        {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={5}> */}
									    <TextField
                                            fullWidth
											label={t("Country")}
                                            {...getFieldProps('country')}
											inputProps={{ list: "global_country_list" }}
                                            error={Boolean(touched.country && errors.country)}
                                            helperText={touched.country && errors.country}
                                        />
                                        <TextField
                                            fullWidth
                                            label={t("Address")}
                                            {...getFieldProps('address')}
                                            error={Boolean(touched.address && errors.address)}
                                            helperText={touched.address && errors.address}
                                        />
                                        {/* </Stack> */}
                                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={5}>
                                            <TextField
                                                fullWidth
                                                label={t("ResNo")}
                                                {...getFieldProps('regnumber')}
                                                error={Boolean(touched.regnumber && errors.regnumber)}
                                                helperText={touched.regnumber && errors.regnumber}
												
                                            />

                                            <TextField
                                                fullWidth
                                                label={t("TaxNo")}
                                                {...getFieldProps('taxnumber')}
                                                error={Boolean(touched.taxnumber && errors.taxnumber)}
                                                helperText={touched.taxnumber && errors.taxnumber}
												
                                            />
                                        </Stack>
										<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={5}>
											 <FormControl >
												<label htmlFor="contained-button-file">
													<Input accept="image/*" id="contained-button-file" onChange={(e) => {
														
														if(e.target.files[0]){
															if(!/\/jpg|jpeg|png/.test(e.target.files[0].type)){
																setAlertMessage(t("Please use .jpg or .png image") + "!");	
																setAlertType("error");
																setAlertOpen(true);
																return;
															}
														}
														
														setLogoPreview(e.target.files[0]); 
														setFiles(e.target.files[0]);
														
														}} type="file" style={{ display: "none" }} />
													<Button endIcon={<ImageIcon />} variant="contained" color="secondary" component="span" size="small" fullWidth>
														{t("Logo upload")}
													</Button>
												</label>
											</FormControl>
											<div className='upload-preview'></div>
										</Stack>

                                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} paddingTop={1}>
                                            <LoadingButton
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                endIcon={<CheckIcon />}
                                                variant="contained"
                                                loading={isSubmitting}
                                            >
                                                {t("Create")}
                                            </LoadingButton>
                                            
                                        </Stack>

                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Page>
    );
}
