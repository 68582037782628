import { Container, Grid, Stack, Typography} from '@mui/material';
import Page from '../components/Page';
import { forwardRef, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { fetchUtils } from 'react-admin';
import jsonServerProvider from '../data_provider';
import configData from "../config.json";
//import SelectSite from './SelectSite'
import SupportTicketsList from './SupportTicketsList'
import plusFill from '@iconify/icons-eva/plus-fill';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Icon } from '@iconify/react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function EcommerceShop() {
  const { t } = useTranslation();
  const history = useHistory();
  
  const AlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };
  
  const [AlertMessage, setAlertMessage] = useState("success");
  const [AlertType, setAlertType] = useState("success");
  const [AlertOpen, setAlertOpen] = useState(false);

  const httpClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json', xcompanyid: sessionStorage.company_id, xsiteid: sessionStorage.site_id  });
    }
    const token = JSON.parse(sessionStorage.AccessToken || "{}").Token;
    options.headers.set('Authorization', `${token}`);
    return fetchUtils.fetchJson(url, options);
  };
  const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);
  const MainUserId = JSON.parse(sessionStorage.AccessToken || "{}").UserId
  const createnewticket = (params) => {
	  
	if(!sessionStorage.company_id){
		setAlertMessage(t("You must select a company so you could raise a new ticket concerning it") + "!");	
		setAlertType("error");
		setAlertOpen(true);
		return;
	}  
	  
    history.push('/newticket');
  }

  const load = (params) => {
    // dataProvider.getList('tickets', {
      // pagination: { page: 1, perPage: 9999 }, sort: { field: 'id', order: 'DESC' },
      // filter: { CompanyId: sessionStorage.company_id },
    // })
      // .then(res => {
      // })
      // .catch(error => {
        // console.log(error)
      // })
  }

  useEffect(() => {
    load()
    // eslint-disable-next-line
  }, [])

  return (
    <Page title=" Ticket | Holest">
	  <Snackbar open={AlertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={AlertClose}>
        <Alert onClose={AlertClose} severity={AlertType} sx={{ width: '100%' }}>
          {AlertMessage}
        </Alert>
      </Snackbar>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" >
            {t("Support tickets")}
          </Typography>
          {/*
          <SelectSite reload={loadData} />
		  */}
          
		  <Button
            variant="contained"
            onClick={(e) => createnewticket()}
            startIcon={<Icon icon={plusFill} />}
            color="secondary"
          >
            {t("Create a new support ticket")}
          </Button>
		  
        </Stack>
        <Grid container>
          <Grid item xs={12} justifyContent="center">
            <SupportTicketsList />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
